<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link  c-breadcrumbs__home"
            @click="goToHome"
          >
            <md-icon>home</md-icon>
            <span>Home</span>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Sections</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper">
        <base-table-component
          :prop-data="sections"
        >
          <template v-slot:action>
            <div class="header-table-section">
              Sections
            </div>
            <div class="add-button">
              <div>
                List of all sections
              </div>
              <div
                v-if="canWrite(`${PERMISSION_NAMES.TRANSLATIONS.key}.${PERMISSION_NAMES.TRANSLATIONS.key}`)"
                class="add-section"
              >
                <img src="../../../../assets/add-button.svg" class="add-button__new" alt="" @click="createOrUpdateUser()"><span>Add New Section</span>
              </div>
            </div>
          </template>
          <template v-slot:default="slotProps">
            <span class="c-table__btn-wrap">
              <generic-button
                icon
                transparent
                text-inherit
                :disabled="!canWrite(`${PERMISSION_NAMES.TRANSLATIONS.key}.${PERMISSION_NAMES.TRANSLATIONS.key}`)"
                @click="createOrUpdateUser(slotProps.selected.word)"
              >
                <md-tooltip
                  md-direction="top"
                >
                  Edit
                </md-tooltip>
                <img src="../../../../assets/edit-button.svg" alt="">
              </generic-button>
            </span>
            <span class="c-table__btn-wrap">
              <generic-button
                icon
                transparent
                text-inherit
                :disabled="!canWrite(`${PERMISSION_NAMES.TRANSLATIONS.key}.${PERMISSION_NAMES.TRANSLATIONS.key}`)"
                @click="openDeleteSectionModal(slotProps.selected.word)"
              >
                <md-tooltip md-direction="top">
                  Delete
                </md-tooltip>
                <img src="../../../../assets/delete-button.svg" alt="">
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>
      <paginate
        v-if="shouldShowPagination"
        :page-count="paginationData.pageCount"
        :page-range="limitPerPage"
        :prev-text="'<'"
        :next-text="'>'"
        :force-page="currentPage"
        :click-handler="fetchSection"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import SectionModal from './components/create-update/index';
import { sectionService } from '../../../../services/section/index';
import { paginationService } from '../../../../services/pagination/index';
import BaseTableComponent from '../../../../shared/table/BaseTableComponent';
import DeleteModalComponent from '../../../../shared/delete-modal/DeleteModalComponent';
import GenericButton from "../../../../shared/buttons/GenericButton";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {PERMISSION_NAMES} from "@/const/online-permissions";

export default {
  components: {
    BaseTableComponent,
    GenericButton
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      permissionName: `${PERMISSION_NAMES.TRANSLATIONS.key}.${PERMISSION_NAMES.TRANSLATIONS.key}`,

      sections: [],
      paginationData: {},
      limitPerPage: 10,
      currentPage: 1
    }
  },
  computed: {
    PERMISSION_NAMES() {
      return PERMISSION_NAMES
    },
    shouldShowPagination() {
      return this.paginationData.totalItems > this.limitPerPage
    },
  },
  created () {
    this.fetchSection();
  },
  methods: {
    fetchSection(currentPage = 1, limit = 10) {
      this.currentPage = currentPage;
      sectionService.onlineGetAllSections(currentPage, limit, true).then(response => {
        if (response.data.items.length === 0 && this.currentPage > 1) {
          return this.fetchSection(this.currentPage - 1);
        }

        this.paginationData = paginationService.getPaginationData(response.data);
        this.sections = sectionService.prepareSectionFromResponse(response.data.items);
      });
    },

    goToHome() {
      return this.$router.push({ name: 'home' });
    },

    openDeleteSectionModal(selected) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `key ${selected.key}`,
          propData: selected.id,
          deleteFunction: this.deleteSection,
          getDataFunction: this.fetchSection,
          pageData: {currentPage: this.currentPage, limit: this.limitPerPage }
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    createOrUpdateUser(selected) {
      this.$modal.show(
        SectionModal,
        {
          sectionData: sectionService.formatSectionData(selected),
          currentPage: this.currentPage,
          fetchSection: this.fetchSection
        },
        { height: "auto", scrollable: true }
      );
    },

    deleteSection(id) {
      return sectionService.onlineDeleteSection(id);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>
