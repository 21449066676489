<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link c-breadcrumbs__home"
            @click="goToHome"
          > 
            <md-icon>home</md-icon>
            <span>Home</span>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Manage Drivers</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <md-tabs class="c-tabs c-tabs--large">
        <md-tab
          v-for="tab in manageAppTabs"
          :id="tab.tab"
          :key="tab.tab"
          :md-label="tab.label"
          @click="changeActiveTab(tab.tab)"
        />
      </md-tabs>
      <component :is="currentTab" v-if="userCanSee(currentTab)" />
    </div>
  </div>
</template>

<script>
import { manageAppTabs } from '../../const';
import Drivers from './app-manger-dirvers';
import each from 'lodash/each';
import { mapGetters } from 'vuex';

export default {
  components: {
    Drivers,
  },
  data() {
    return {
      currentTab: 'drivers',
    };
  },

  computed: {
    ...mapGetters('account', ['userReadableSections', 'userWriteableSections', 'userAllowedRoutes', 'user']),
    manageAppTabs() {
      return manageAppTabs.filter(
        tab =>
          this.userCanSee(tab.tab)
      );
    },
  },

  created() {
    each(manageAppTabs, tab => {
      if (this.userCanSee(tab.tab)) {
        this.currentTab = tab.tab;
        return false;
      }
    })
  },

  methods: {
    changeActiveTab(tab) {
      this.currentTab = tab;
    },
    goToHome() {
      return this.$router.push({ name: 'home' });
    },
    userCanSee(tab) {
      return this.userWriteableSections.includes(tab) ||
        this.userReadableSections.includes(tab)
    }
  },
};
</script>

<style></style>
