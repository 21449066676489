<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link"
            @click="goToHome"
          > 
            <md-icon>home</md-icon>
            <span>Home</span>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link c-breadcrumbs__home">Companies</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <div class="u-flex-row-end u-ph-md">
        <div class="c-search u-ml-auto u-mr-md">
          <md-field
            md-inline
            class="u-width-m u-mb-xs"
          >
            <label>Search</label>
            <md-input
              v-model="term"
              @keydown.enter="searchCompanies"
            />
            <span @click="searchCompanies()">
              <md-icon>search</md-icon>
            </span>
          </md-field>
        </div>
        <generic-button
          variation="red"
          @click="getCompaniesFromRest"
        >
          Get companies from REST
        </generic-button>
      </div>
      <div class="c-table-wrapper">
        <base-table-component :prop-data="getCompaniesForTable">
          <template v-slot:default="slotProps">
            <generic-button
              icon
              transparent
              @click="goToMarkets(slotProps.selected.rowData.id)"
            >
              <md-tooltip md-direction="top">
                Go to markets
              </md-tooltip>
              <md-icon>arrow_forward_ios</md-icon>
            </generic-button>
          </template>
        </base-table-component>
      </div>

      <template v-if="!getCompaniesForTable.length && isFetchedCompanies">
        <md-empty-state
          md-icon="announcement"
          md-label="No Data"
        />
      </template>

      <paginate
        v-if="shouldShowPagination"
        :page-count="getCompanyPaginationData.pageCount"
        :page-range="limitPerPage"
        :prev-text="'<'"
        :next-text="'>'"
        :force-page="currentPage"
        :click-handler="getDataPerPage"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BaseTableComponent from '../../shared/table/BaseTableComponent';
import GenericButton from '../../shared/buttons/GenericButton';

export default {
  components: {
    BaseTableComponent,
    GenericButton,
  },

  data() {
    return {
      currentPage: 1,
      limitPerPage: 10,
      term: '',
      defaultValues: {
        currentPage: 1,
        limitPerPage: 10,
        term: ''
      }
    };
  },

  computed: {
    ...mapGetters('company', [
      'getCompaniesForTable',
      'isFetchedCompanies',
      'getCompanyPaginationData',
    ]),

    shouldShowPagination() {
      return this.getCompanyPaginationData.totalItems > this.limitPerPage;
    },
  },

  async created() {
    this.getDataPerPage();
  },

  methods: {
    ...mapActions('company', ['getCompaniesFromRest', 'getCompanies']),

    goToMarkets(companyId) {
      this.$router.push({ name: 'markets', params: { companyId } });
    },

    goToHome() {
      this.$router.push({ name: 'home' });
    },

    async fetchCompanies(page, limit, term) {
      await this.getCompanies({ page, limit, term });
    },

    getDataPerPage(
      currentPage = 1,
      limit = this.defaultValues.limitPerPage,
      term = this.term
    ) {
      this.currentPage = currentPage;
      this.fetchCompanies(currentPage, limit, term);
    },

    searchCompanies() {
      this.getDataPerPage(
        this.defaultValues.currentPage,
        this.defaultValues.limitPerPage,
        this.term
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
