<template>
  <div class="u-box c-modal">
    <div class="c-modal-header u-flex-justify-between">
      <h4 class="md-title u-typography-color">
        Language list for key: {{ word.key }}
      </h4>
      <generic-button
        icon
        transparent
        @click="close()"
      >
        <md-icon>
          clear
        </md-icon>
      </generic-button>
    </div>

    <md-table>
      <md-table-row>
        <md-table-head class="c-table-header-title">
          Language
        </md-table-head>
        <md-table-head class="c-table-header-title">
          Translation
        </md-table-head>
      </md-table-row>
      <md-table-row v-for="translation in translations" :key="translation.id">
        <md-table-cell>{{ translation.language.name }}</md-table-cell>
        <md-table-cell>{{ translation.phrase }}</md-table-cell>
      </md-table-row>
    </md-table>
    <md-divider class="u-width" />
  </div>
</template>

<script>
import GenericButton from "../../../../../shared/buttons/GenericButton";

export default {
  name: "ListTranslationModal",
  components: {
    GenericButton
  },
  props: {
    translations: {
      type: Array,
      required: true,
    },
    word: {
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
