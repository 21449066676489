import {PERMISSION_NAMES} from "@/const/online-permissions";

export const defaultOnLineSidebarItems = [
  {
    key: "users",
    pathName: "on-line-users",
    icon: "accessibility",
    text: "Users",
    onlyAdmins: true,
  },
  {
    key: "translations",
    pathName: null,
    icon: "dehaze",
    text: "Translations",
    requiredPermissions: [`${PERMISSION_NAMES.TRANSLATIONS.key}.${PERMISSION_NAMES.TRANSLATIONS.key}`],
    subItems: [
      {
        key: "all-translations",
        pathName: "on-line-all-translations",
        icon: "assignment",
        text: "All translations",
      },
      {
        key: "languages",
        pathName: "on-line-languages",
        icon: "language",
        text: "Languages",
      },
      {
        key: "sections",
        pathName: "on-line-sections",
        icon: "folder",
        text: "Sections",
      }
    ],
  },
  {
    key: "websites",
    pathName: "websites",
    icon: "web",
    text: "Websites",
    requiredPermissions: [
      `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.WEBSITES_CONFIGURATION.key}`,
      `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`,
      `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.TOOLTIPS.key}`,
    ]
  },
  {
    key: "configurations",
    pathName: "on-line-configurations",
    icon: "settings",
    text: "Configurations",
    requiredPermissions: [
      `${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.TOOLTIPS.key}`,
    ]
  },
  {
    key: "configurations-setup",
    pathName: null,
    icon: "settings",
    text: "Configurations Setup",
    onlyDevelopers: true,
    subItems: [
      {
        key: "online-configuration-sections",
        pathName: "online-configuration-sections",
        icon: "description",
        text: "Sections",
      },
      {
        key: "online-configuration-types",
        pathName: "online-configuration-types",
        icon: "description",
        text: "Types",
      },
      {
        key: "online-configuration-settings",
        pathName: "online-configuration-settings",
        icon: "description",
        text: "Configuration settings",
      },
    ],
  },
];
