<template>
  <div class="l-main">
    <div class="l-main__ctn">
      <template>
        <h2 v-if="fetchedResults && sportsWithTooltips && sportsWithTooltips.length === 0">
          No tooltips found
        </h2>

        <div class="c-table-wrapper">
          <base-table-component :prop-data="sportsWithTooltipsTableData" :action-label="'Tooltips enabled'">
            <template v-slot:action>
              <div class="header-table-section">
                Tooltips
              </div>
              <div class="add-button">
                <div class="add-section">
                  <div class="c-search">
                    <md-field class="u-width-m u-mb-xs u-mr-md">
                      <label>Search</label>
                      <md-input v-model="searchQuery" @input="handleSearch" />
                    </md-field>
                  </div>
                </div>
              </div>
            </template>
            <template v-slot:default="slotProps">
              <span class="c-table__btn-wrap c-table__btn-wrap--default-cursor">
                <md-switch
                  v-model="enabledSportIds"
                  style="margin: 0 !important;"
                  class="md-primary"
                  title="Are tooltips for sport enabled on this website"
                  :value="slotProps.selected.sportWithTooltip.sportId"
                  :disabled="!canWrite(`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.TOOLTIPS.key}`)"
                  @change="handleChange(slotProps.selected.sportWithTooltip.sportId)"
                />
              </span>
            </template>
          </base-table-component>
        </div>

        <paginate
          v-if="totalPages > 1"
          v-model="currentPage"
          :page-count="totalPages"
          :prev-text="'<'"
          :next-text="'>'"
          :page-range="20"
          :container-class="'c-pagination'"
          :prev-link-class="'md-button prev md-elevation-1'"
          :next-link-class="'md-button next md-elevation-1'"
          :page-link-class="'md-button md-elevation-1'"
          :active-class="'active'"
          :disabled-class="'disabled'"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import { tooltipsService } from '@/services/tooltips';
  import BaseTableComponent from '@/shared/table/BaseTableComponent';
  import { mapActions } from 'vuex';
  import each from 'lodash/each';
  import { responseService } from '@/services/response';
  import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
  import {PERMISSION_NAMES} from "@/const/online-permissions";

  export default {
    name: 'Tooltips',

    components: {
      BaseTableComponent,
    },

    mixins: [onlinePermissionsMixin],

    props: {
      website: {
        type: Object,
        default: null,
      },
    },

    data() {
      return {
        permissionName: `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.TOOLTIPS.key}`,

        searchQuery: '',

        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 10,

        fetchedResults: false,
        showAllSportsWithTooltips: false,
        sportsWithTooltips: [],
        filteredSportsWithTooltips: [],
        sports: [],
        selectedSport: {},
        enabledSportIds: [],
      };
    },

    computed: {
      PERMISSION_NAMES() {
        return PERMISSION_NAMES
      },
      sportsWithTooltipsTableData() {
        if (!this.filteredSportsWithTooltips) {
          return [];
        }

        const currentPage = this.currentPage || 1;

        return this.filteredSportsWithTooltips.map(sportWithTooltip => ({
          sportWithTooltip: sportWithTooltip,
          rowData: {
            'Sport Id': sportWithTooltip.sportId,
            'Sport': sportWithTooltip?.name,
          }
        }))
        .filter(sportWithTooltip => {
          return sportWithTooltip.sportWithTooltip.name.toLowerCase().includes(this.searchQuery.toLowerCase())
              || sportWithTooltip.sportWithTooltip.sportId.toString().includes(this.searchQuery)
        })
        .slice((currentPage - 1) * this.itemsPerPage, currentPage * this.itemsPerPage);
      },
    },

    async created() {
      await this.getSports();
      await this.getSportsWithTooltips();
    },

    methods: {
      ...mapActions("response", ["setGlobalError"]),

      async getSportsWithTooltips() {
        const sportsWithTooltipsResponse = await tooltipsService.getAllSportsWithTooltips();
        this.sportsWithTooltips = sportsWithTooltipsResponse?.data?.items;
        this.filteredSportsWithTooltips = this.sportsWithTooltips.map(sportWithTooltip => {
          return {
            ...sportWithTooltip,
            name: this.sports.find(sport => sport.sportId === sportWithTooltip.sportId)?.name
          }
        });
        this.fetchedResults = true;

        this.handlePaginationParameters();
        this.setToggleState();
      },

      async getSports() {
        if (this.sports.length) {
          return;
        }

        const sportsResponse = await tooltipsService.getSports();
        this.sports = sportsResponse?.data;
      },

      setToggleState() {
        each(this.filteredSportsWithTooltips, sport => {
          if (JSON.parse(sport.websiteIds).includes(this.website.id)) {
            this.enabledSportIds.push(sport.sportId)
          }
        })
      },

      async handleChange(sportId) {
        const isInitialEnabled = this.enabledSportIds.some(id => id === sportId);

        if (!sportId || !this.website.id) {
          return;
        }

        const toggleData = {
          websiteId: this.website.id,
          sportId: sportId
        }

        try {
          await tooltipsService.toggleTooltipSportForWebsite(toggleData);
        } catch (error) {
          this.setGlobalError(responseService.getErrorMessage(error.data.message));

          if (isInitialEnabled) {
            return this.enabledSportIds = this.enabledSportIds.filter(id => id !== sportId)
          }

          this.enabledSportIds.push(sportId);

        }
      },

      handleSearch() {
        const filteredSports = this.filteredSportsWithTooltips.filter(sportWithTooltip => {
          return sportWithTooltip.name.toLowerCase().includes(this.searchQuery.toLowerCase())
              || sportWithTooltip.sportId.toString().includes(this.searchQuery)
        })

        this.handlePaginationParameters(filteredSports.length)
      },

      handlePaginationParameters(totalItemsCount = null) {
        const tooltipsCount = totalItemsCount !== null ? totalItemsCount : this.sportsWithTooltips.length;

        this.totalPages = Math.ceil(tooltipsCount / this.itemsPerPage)
        this.totalItems = tooltipsCount;

        if (this.currentPage > this.totalPages) {
          this.currentPage = this.totalPages;
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.c-back-button {
  min-height: 36px;
}

.md-switch {
  cursor: pointer;

  &.md-disabled {
    opacity: 0.4;
    cursor: default;
  }
}
</style>
