<template>
  <div :class="['c-form-group', { 'c-form-group--disabled' : disabled }]">
    <div class="c-form-group__label">
      <label>{{ label }}</label>
    </div>
    <div class="c-form-group__action">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'GenericFormGroup',
  props: {
    label: {
      type: String,
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    }
  }
};
</script>
