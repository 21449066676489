import {mapGetters} from "vuex";
import router from "@/router/router";
import {defaultOnLineSidebarItems} from "@/const/on-line-sidebar-items";
import {PERMISSION_NAMES} from "@/const/online-permissions";

export const onlinePermissionsMixin = {
  data() {
    return {
      permissionName: '',
      onlyDevelopersAllowed: false,
    }
  },

  created() {
    if (this.isDeveloper() || !this.permissionName) {
      return;
    }

    if (this.onlyDevelopersAllowed) {
      return router.push({name: 'home'});
    }

    if (!this.canRead(this.permissionName)) {
      router.push({name: 'home'});
    }
  },

  watch: {
    website() {
      if (!this.canReadWebsite(this.website.id)) {
        router.push({name: 'home'});
      }
    }
  },

  computed: {
    ...mapGetters('account', ['userOnlinePermissions', 'user']),
  },

  methods: {
    canRead(permission) {
      return this.isAdmin()
        || this.userOnlinePermissions?.permissions[permission] === 'read'
        || this.userOnlinePermissions?.permissions[permission] === 'write'
    },
    canWrite(permission) {
      return this.isAdmin()
        || this.userOnlinePermissions?.permissions[permission] === 'write'
    },
    canReadWebsite(websiteId) {
      return this.isAdmin()
        || this.userOnlinePermissions?.onlineWebsitePermissions[websiteId] === 'write'
        || this.userOnlinePermissions?.onlineWebsitePermissions[websiteId] === 'read'
    },
    canWriteWebsite(websiteId) {
      return this.isAdmin()
        || this.userOnlinePermissions?.onlineWebsitePermissions[websiteId] === 'write'
    },
    isDeveloper() {
      return this.user.isDeveloper
    },
    isAdmin() {
      return this.user.role === 1 || this.isDeveloper();
    },
    canReadAny(permissions = []) {
      return permissions.some(permission => this.canRead(permission));
    },
    getFirstAllowedRoutePathName() {
      if (this.canReadAny([
        `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.WEBSITES_CONFIGURATION.key}`,
        `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`,
        `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.TOOLTIPS.key}`,
      ])) {
        return 'websites';
      }

      const firstAllowedSidebarItem = defaultOnLineSidebarItems.find(sidebarItem => {
        if (sidebarItem.onlyAdmins) {
          return this.isAdmin();
        }

        if (sidebarItem.onlyDevelopers) {
          return this.isDeveloper();
        }

        if (sidebarItem.requiredPermissions) {
          return this.canReadAny(sidebarItem.requiredPermissions);
        }

        return true;
      })

      if (!firstAllowedSidebarItem) {
        return;
      }

      return firstAllowedSidebarItem.pathName || firstAllowedSidebarItem.subItems[0].pathName
    }
  },
}
