export const PERMISSION_NAMES = {
  "TRANSLATIONS": { key: "TRANSLATIONS", label: "Translations" },
  "WEB_SITES": { key: "WEB_SITES", label: "Web sites" },
  "WEBSITES_CONFIGURATION": { key: "WEBSITES_CONFIGURATION", label: "Web sites configuration" },
  "CONFIGURATION": { key: "CONFIGURATION", label: "Configuration" },
  "TOOLTIPS": { key: "TOOLTIPS", label: "Tooltips" },
  "SEO_RULES": { key: "SEO_RULES", label: "Seo rules" },
  "BIG_JACKPOT": { key: "BIG_JACKPOT", label: "Big Jackpot" },
};

export const onlinePermissions = {
  [`${PERMISSION_NAMES.TRANSLATIONS.key}.${PERMISSION_NAMES.TRANSLATIONS.key}`]: '',

  [`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.TOOLTIPS.key}`]: '',
  [`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.BIG_JACKPOT.key}`]: '',

  [`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.WEBSITES_CONFIGURATION.key}`]: '',
  [`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`]: '',
  [`${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.TOOLTIPS.key}`]: '',
};
