<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link c-breadcrumbs__home" @click="goToHome">
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link c-breadcrumbs__home" @click="goToCompanies">Companies</a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link c-breadcrumbs__home" @click="goToMarkets">{{
            companyName
          }}</a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link c-breadcrumbs__home" @click="goToBetshops">{{
            marketName
          }}</a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link c-breadcrumbs__home">{{ betshopName }}</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <md-tabs class="c-tabs c-tabs--large" :md-active-tab="selectedTab">
        <md-tab
          v-for="tab in availableTabs"
          :id="tab.tab"
          :key="tab.tab"
          :md-label="tab.label"
          @click="changeActiveTab(tab.tab)"
        />
      </md-tabs>
      <component
        :is="currentTab"
        v-if="availableTabs.length"
        :betshop="currentBetshop"
        :fetch-trigger="fetchTrigger"
        :api-configurations-data="getApiConfigurationsData"
        :api-selected-configurations-data="getApiBetshopConfigurationsData"
        :type-id="betshopId"
        :type="typeName"
        @generateNewLicenceKey="generateNewLicenceKey"
        @deleteLicenceKey="deleteLicenceKey"
        @canChangeTab="canChangeTab"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GeneralSettings from "./pages/general-settings.vue";
import LicenceKeys from "./pages/licence-keys";
import GenericButton from "../../shared/buttons/GenericButton";
import PageConfigurations from "../betshops/components/page-configurations";
import TicketSettings from "../betshops/components/ticket-settings";
import ShiftSettings from "../betshops/components/shift-settings";
import DisabledPages from "../betshops/components/disabled-pages";
import parseInt from "lodash/parseInt";
import get from "lodash/get";
import each from "lodash/each";
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { betshopTabs } from "../../const";
import { betshopService } from "../../services/betshop";
import { isTabOrSubTabAvailable } from '../../helpers/permission';
import ConfirmationModalComponent from '../../shared/confirmation-modal/ConfirmationModalComponent';

export default {
  components: {
    GeneralSettings,
    GenericButton,
    PageConfigurations,
    LicenceKeys,
    TicketSettings,
    ShiftSettings,
    DisabledPages
  },

  data: () => {
    return {
      currentTab: "general-settings",
      currentBetshop: null,
      fetchTrigger: 1,
      betshopId: null,
      typeName: "betshop",
      availableTabs: [],
      selectedTab: 'general-settings',
      previousTab: 'general-settings',
      hasWritePermision: false,
      initApiConfigurations: {},
      canOpenModal: false,
    };
  },

  beforeRouteLeave(to, from, next) {
    if (this.checkPageFormChanges()) {
      this.openConfirmationModal(() => { next() });
      return;
    }
    return next();
  },

  computed: {
    ...mapGetters("company", [
      "getApiConfigurationsData",
      "getApiBetshopConfigurationsData",
    ]),
    betshopTabs() {
      return betshopTabs;
    },
    betshopName() {
      return get(this.currentBetshop, "name");
    },
    marketName() {
      return get(this.currentBetshop, "market.name");
    },
    companyName() {
      return get(this.currentBetshop, "market.company.name");
    },
  },

  async created() {
    const betshopId = parseInt(get(this.$route, "params.betshopId"));
    await this.fetchConfigurationsAndBetshopConfigurations(betshopId);
    this.getBetshop();
    this.getBetshopLogoFromBe(this.$route.params.betshopId);
    this.setPermissions();
    this.initApiConfigurations = cloneDeep(this.getApiBetshopConfigurationsData);
  },

  destroyed() {
    this.currentBetshop = {};
    this.clearBetshopLogoFromBe();
  },

  methods: {
    ...mapActions("response", ["setGlobalError", "handleSuccess"]),
    ...mapActions("company", [
      "getBetshopLogoFromBe",
      "fetchConfigurationsAndBetshopConfigurations",
      "clearBetshopLogoFromBe"
    ]),
    ...mapGetters('account', ['user', 'userHierarchyAllowedTabs', 'userHierarchyWriteableSections']),

    changeActiveTab(tab) {
      this.selectedTab = tab;
      if (tab === this.currentTab) {
        return;
      }
      
      if (this.canOpenModal) {
        this.openConfirmationModal(() => { this.previousTab = tab; this.currentTab = tab; this.canOpenModal = false; });
        return;
      }
      
      this.currentTab = tab;
      this.previousTab = tab;
    },

    canChangeTab(canOpenModal) {
      if (!canOpenModal) {
        this.initApiConfigurations = cloneDeep(this.getApiBetshopConfigurationsData);
      }
      this.canOpenModal = canOpenModal;
    },

    async getBetshop() {
      try {
        const { data } = await betshopService.get(this.$route.params.betshopId);
        this.betshopId = data.id;
        this.currentBetshop = data;
      } catch (error) {
        this.setGlobalError("Error fetching betshop!");
      }
    },

    goToHome() {
      this.$router.push({ name: "home" });
    },

    goToCompanies() {
      this.$router.push({ name: "companies" });
    },

    goToMarkets() {
      this.$router.push({
        name: "markets",
        params: { companyId: get(this.currentBetshop, "market.companyId") },
      });
    },

    goToBetshops() {
      this.$router.push({
        name: "betshops",
        params: {
          companyId: get(this.currentBetshop, "market.companyId"),
          marketId: get(this.currentBetshop, "marketId"),
        },
      });
    },

    async generateNewLicenceKey() {
      try {
        await betshopService.generateNewLicenceKey(
          get(this.currentBetshop, "id")
        );
        this.fetchTrigger++;
      } catch (error) {
        this.setGlobalError("Error generating new licence key!");
      }
    },

    openConfirmationModal(onAccept) {
      this.$modal.show(
          ConfirmationModalComponent,
          {
            confirmationMsg: `
              Your changes will be lost,
              are you sure you want to continue?`,
            onAccept: onAccept,
            onClose: () => {
              setTimeout(() => {
                this.selectedTab = this.previousTab
              });
            },
          },
          { height: "auto", scrollable: false, width: "400px" }
        );
    },

    checkPageFormChanges() {
      return !isEqual(this.initApiConfigurations, this.getApiBetshopConfigurationsData) || this.canOpenModal;
    },

    async deleteLicenceKey(id) {
      try {
        await betshopService.deleteLicenceKey(id);
        this.fetchTrigger++;
        this.handleSuccess({ message: "Successfully deleted!" });
      } catch (error) {
        this.setGlobalError("Error deleting licence key!");
      }
    },

    setPermissions() {
      if (this.user().role === 1) {
        return this.availableTabs = [...this.betshopTabs];
      }

      each(betshopTabs, tab => {
        if (!isTabOrSubTabAvailable(tab, this.userHierarchyAllowedTabs(), '-betshop')) {
          return;
        }

        this.availableTabs.push(tab);
      });

      if (this.availableTabs.length === 0) {
        return;
      }

      this.currentTab =  this.availableTabs[0].tab;
      this.selectedTab = this.availableTabs[0].tab;
      this.previousTab = this.availableTabs[0].tab;
    }
  },
};
</script>
