import { apiService } from '../api'
import { each, map, findIndex } from 'lodash';

const ENDPOINTS = {
  TRANSLATION: '/translation',
  CREATE: '/translation/save',
  UPDATE: '/translation/update',
  WORD: '/translation/word/',
  ONLINE_TRANSLATION: '/online-translation',
  ONLINE_CREATE: '/online-translation/save',
  ONLINE_BATCH_CREATE: '/online-translation/bulk-save',
  ONLINE_UPDATE: '/online-translation/update',
  ONLINE_WORD: '/online-translation/word/',
  ONLINE_BATCH_DELETE: '/online-translation/bulk-delete',
}

class TranslationService {
  async getTranslations() {
    return await apiService.get(ENDPOINTS.TRANSLATION);
  }

  async save(newTranslation) {
    return await apiService.post(ENDPOINTS.CREATE, newTranslation);
  }

  async update(newTranslation) {
    return await apiService.post(ENDPOINTS.UPDATE, newTranslation);
  }

  async getByWordId(id) {
    return await apiService.get(ENDPOINTS.WORD + id);
  }

  async getOnlineTranslations() {
    return await apiService.get(ENDPOINTS.ONLINE_TRANSLATION);
  }

  async saveOnline(newTranslation) {
    return await apiService.post(ENDPOINTS.ONLINE_CREATE, newTranslation);
  }

  async saveOnlineBatch(newTranslation) {
    return await apiService.post(ENDPOINTS.ONLINE_BATCH_CREATE, newTranslation);
  }

  async updateOnline(newTranslation) {
    return await apiService.post(ENDPOINTS.ONLINE_UPDATE, newTranslation);
  }

  async getOnlineByWordId(id) {
    return await apiService.get(ENDPOINTS.ONLINE_WORD + id);
  }

  async deleteByTooltipKeys(keys) {
    return await apiService.delete(ENDPOINTS.ONLINE_BATCH_DELETE + '?keys=' + keys.join(','));
  }

  formatData(word, languages, oldWord, temporaryWords) {
    if (!word) {
      return { translation: [] }
    }

    const formatted = {
      word: word.key,
      wordId: word.id,
      sectionId: word.section.id,
      section: word.section,
      exceptions: word.exceptions,
      languagesIds: [],
      translation: []
    }

    const sameWordIndex = findIndex(temporaryWords, temporaryWord => temporaryWord.word === oldWord);

    if (sameWordIndex === -1) {
      each(languages, language => {
        const translationForCurrentLanguage =
          word.translation.find(translation => translation.language.id === language.id);

        if (translationForCurrentLanguage) {
          formatted.languagesIds.push(language.id);
          formatted.translation.push({
            translationId: translationForCurrentLanguage.id,
            phrase: translationForCurrentLanguage.phrase,
            languageId: language.id
          })
        } else {
          formatted.languagesIds.push(language.id)
          formatted.translation.push({
            translationId : null,
            phrase: '',
            languageId: language.id
          })
        }
      })
    } else {
      formatted.languagesIds = temporaryWords[sameWordIndex].languagesIds
      formatted.translation = [...temporaryWords[sameWordIndex].translation]
      temporaryWords.splice(sameWordIndex, 1);
    }


    return formatted;
  }

  formatDataForTable(response) {
    return map(response, data => {
      return {
        word: data,
        rowData: {
          section: data.section.title,
          phrase: data.key,
          translation: `${data.section.key}.${data.key}`,
        }
      }
    });
  }

  formatDataForUpdateTranslation(word) {
    if (!word) {
      return { translation: [] }
    }

    return {
      word: word.key,
      wordId: word.id,
      sectionId: word.section.id,
      sectionTitle: word.section.title,
      section: word.section,
      languagesIds: [],
      translation: []
    }
  }
}

export const translationService = new TranslationService()
