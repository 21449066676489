<template>
  <md-button
    :class="`${className}`"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <slot />
  </md-button>
</template>
<script>
export default {
  name: 'GenericButton',
  props: {
    variation: {
      required: false,
      default: ''
    },
    icon: {
      required: false,
      default: false,
      type: Boolean
    },
    transparent: {
      required: false,
      default: false,
      type: Boolean
    },
    fab: {
      required: false,
      default: false,
      type: Boolean
    },
    textInherit: {
      required: false,
      default: false,
      type: Boolean
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    }
  },
  computed: {
    className() {
      return `
        ${this.variation && 'btn-' + this.variation}
        ${this.icon ? 'md-icon-button' : ''}
        ${this.transparent ? '' : 'md-raised'}
        ${this.fab ? 'md-fab' : ''}
        ${this.textInherit ? 'text-inherit' : ''}
      `.trim();
    }
  }
};
</script>

<style lang="scss">
.btn-red        { background: $red          !important; }
.btn-grey       { background: $grey         !important; }
.btn-green      { background: $green        !important; }
.btn-blue       { background: $blue         !important; }
.btn-grey-light { background: $grey-light   !important; }

.md-button {
  margin: 0 !important;
  cursor: pointer;

  &.btn-red {
    color: $white !important;
  }

  &.text-inherit {
    &,
    .md-icon-font {
      color: inherit !important;
      text-transform: inherit !important;
    }
  }

  &.btn-grey {
    color: $white !important;
  }

  &[disabled] {
    opacity: 0.5 !important;
    cursor: default;
    pointer-events: none;
  }
}
</style>
