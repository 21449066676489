<template>
  <div class="l-main u-ph-md u-pv-xs">
    <h4 class="u-mb-0">
      Upload new <span class="u-text-capitalized">{{ type }}</span> version
    </h4>
    <form id="uploadForm" enctype="multipart/form-data" @submit="uploadFiles($event)">
      <div class="md-layout md-gutter">
        <div class="md-layout-item">
          <md-field>
            <label>{{ system.label }}</label>
            <md-file :ref="system.refName" :disabled="dataIsSubmiting" :placeholder="system.placeholder" @md-change="selectedFile($event, system)" />
          </md-field>
          <span v-if="!!system.error" class="md-error">{{ system.error }}</span>
        </div>

        <div v-if="system.yml" class="md-layout-item">
          <md-field>
            <label>{{ system.yml.label }}</label>
            <md-file :ref="system.yml.refName" :disabled="dataIsSubmiting" :placeholder="system.yml.placeholder" @md-change="selectedFile($event, system.yml)" />
          </md-field>
          <span v-if="!!system.yml.error" class="md-error">{{ system.yml.error }}</span>
        </div>
      </div>

      <div class="u-flex-justify-end u-align-items-center">
        <div v-if="dataIsSubmiting" class="u-flex-row-center">
          <div v-if="uploadProgress > 0" class="u-flex-row-center">
            <span class="u-ml-md">Uploading: {{ uploadProgress }} %</span>
            <md-progress-spinner v-if="uploadProgress < 100" :md-value="uploadProgress" class="u-ml-xs u-mr-md" :md-diameter="30" md-mode="determinate" />
            <span v-else class="u-ml-xs u-mr-md">
              <md-icon class="c-icon c-icon--green">done</md-icon>
            </span>
          </div>
        </div>

        <generic-button :disabled="dataIsSubmiting || isFileSelected()" variation="red" type="submit">
          Upload files
        </generic-button>
      </div>
    </form>
    <div class="u-mt-lg u-flex-full-height">
      <h5 class="u-mb-md">
        Uploaded <span class="u-text-capitalized">{{ type }}</span> version
      </h5>
      <div
        class="c-table c-table--versions"
      >
        <div class="c-table__head">
          <div
            v-for="field in fields"
            :key="field"
            class="c-table__cell"
          >
            {{ field }}
          </div>
        </div>
        <div class="c-table__body">
          <div v-if="currentApp" class="c-table__row">
            <div class="c-table__cell">
              {{ currentApp.name }}
            </div>
            <div class="c-table__cell">
              {{ currentApp.version }}
            </div>
            <div class="c-table__cell">
              {{ formatTime }}
            </div>
            <div class="c-table__cell">
              {{ uploadedBy }}
            </div>

            <div class="c-table__cell">
              <div class="c-table__btn-wrap">
                <generic-button
                  icon
                  transparent
                  text-inherit
                  @click="approveApp()"
                >
                  <md-tooltip md-direction="top">
                    Approve
                  </md-tooltip>
                  <md-icon>done</md-icon>
                </generic-button>
                <generic-button
                  icon
                  transparent
                  text-inherit
                  @click="downloadApp()"
                >
                  <md-tooltip md-direction="top">
                    Download
                  </md-tooltip>
                  <md-icon>download</md-icon>
                </generic-button>
                <generic-button
                  icon
                  transparent
                  text-inherit
                  @click="deleteApp()"
                >
                  <md-tooltip md-direction="top">
                    Delete
                  </md-tooltip>
                  <img src="../../../../assets/delete-button.svg" alt="">
                </generic-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import GenericButton from '../../../../shared/buttons/GenericButton';
import { appManagerService } from "../../../../services/app-manager";
import { systems, testFields } from '../../../../const/systems';
import { isEmpty, get, first } from 'lodash';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import config from '../../../../../config/local'

export default {
  name: "Test",

  components: {
    GenericButton
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      uploadProgress: 0,
      system: {
        ...systems[this.type]
      },
      dataIsSubmiting: false,
      currentApp: null,
      fields: testFields
    }
  },

  computed: {
    ...mapGetters("response", ["success", "error"]),

    formatTime() {
      return moment(this.currentApp.createdAt).format('DD/MM/YYYY HH:mm')
    },

    uploadedBy() {
      return this.currentApp?.updatedBy ? this.currentApp.updatedBy.email : ''
    }
  },

  watch: {
    type (newVal) {
      this.system = systems[newVal];
      this.$refs[this.system.refName].clearField();
      this.$refs[this.system.yml.refName].clearField();
      this.fetchApp();
    }
  },

  created() {
    this.fetchApp();
  },

  methods: {
    ...mapActions('response', ['handleSuccess', 'setGlobalError', 'resetError']),

    calculateUploadedData(progressEvent) {
      return parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
    },

    async fetchApp() {
      try {
        const app = await appManagerService.getAppByStatus('test', this.type);
        this.currentApp = first(app.data);
      } catch (error) {
        this.setGlobalError(error.data.message);
      }
    },

    async downloadApp() {
      const link = document.createElement('a');
      link.setAttribute('download', this.currentApp.name);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.setAttribute('href', `${config.BASE_DOWNLOAD_URL}/${this.currentApp.downloadUrl}`);
      link.click();
      link.remove();
    },

    async deleteApp() {
      try {
        await appManagerService.deleteAppData(this.currentApp.id)
        this.currentApp = null;
        this.handleSuccess({ message: 'App has been removed.' })
      } catch (error) {
        this.setGlobalError(error.data.message);
      }
    },

    async approveApp() {
      try {
        this.currentApp.status = 'approved';
        await appManagerService.updateStatus(this.currentApp)
        this.currentApp = null;
        this.handleSuccess({ message: 'App has been approved.' })
      } catch (error) {
        this.setGlobalError(error.data.message);
      }
    },

    selectedFile(file, system) {
      if(this.dataIsSubmiting) {
        return;
      }

      this.system.error = ''

      if (this.validateFileType(system, file)) {
        this.showError('Wrong file type');

        return;
      }

      if (this.validateYmlFileName(system, file)) {
        this.showError('Yml file do not belongs to this system!');

        return;
      }

      system.file = file;
    },

    isFileSelected() {
      return isEmpty(this.system.file) || isEmpty(this.system.yml.file);
    },

    validateFileType(system, file) {
      const fileName = get(file, '[0].name').split('.');

      return !system.fileType.includes(fileName[fileName.length - 1]);
    },

    validateYmlFileName(system, file) {
      if (!system.ymlName) {
        return false;
      }

      return get(file, '[0].name', '') !== system.ymlName;
    },

    showError(message) {
      this.system.error = message;
    },

    formatFile(system) {
      if (isEmpty(system.file)) {
        return;
      }

      const formData = new FormData();
      formData.append('file', system.file[0]);

      return formData;
    },

    getVersion() {
      const name = this.system.file[0].name;

      if (name.includes('AppImage')) {
        return name.split('betty-app-')[1].split('.AppImage')[0];
      }

      if (name.includes('zip')) {
        return name.split('betty-app-')[1].split('.zip')[0];
      }

      if (name.includes('exe')) {
        return name.split('betty-app-')[1].split('.exe')[0];
      }
    },

    clearInputSystems() {
      this.$refs[this.system.refName].clearField();
      this.$refs[this.system.yml.refName].clearField();
      this.uploadProgress = 0;
    },

    async saveUploadedFilesToDatabase(version) {
      try {
        const uploadedApp = await appManagerService.saveUploadedApps({
          name: this.system.file[0].name,
          downloadUrl: `versions/${this.type}/${version}/${this.system.file[0].name}`,
          platform: this.type,
          version: version,
          status: 'test'
        });

        this.currentApp = uploadedApp.data;
      } catch (error) {
        this.setGlobalError(error.data.message);
      }
    },

    async uploadFiles(event) {
      event.preventDefault();

      if(this.isFileSelected() || this.dataIsSubmiting) {
        return;
      }

      if (!isEmpty(this.currentApp)) {
        return this.setGlobalError('Already has one test version');
      }

      if (isEmpty(this.system.file) || isEmpty(this.system.yml)) {
        return this.setGlobalError('File is required');
      }

      try {
        const version = this.getVersion();

        await this.validateAndUploadFiles(version);
        await this.saveUploadedFilesToDatabase(version);

        this.handleSuccess({ message: 'Uploading App Success.' })
        this.clearInputSystems();
        this.dataIsSubmiting = false;
      } catch (err) {
        this.dataIsSubmiting = false;
        this.setGlobalError(err.data.message);
      }

      event.target.reset();
    },

    async validateAndUploadFiles(version) {
      const file = this.formatFile(this.system);
      const ymlFile = this.formatFile(this.system.yml);

      this.dataIsSubmiting = true;

      await appManagerService.uploadApps(
        file,
        { onUploadProgress: (progressEvent) => {
          this.uploadProgress = this.calculateUploadedData(progressEvent) }
        },
        version,
        this.type
      );

      await appManagerService.uploadApps(ymlFile, () => {} , version, this.type);
    }
  },
};

</script>
