<template>
  <div class="l-sport-tooltips">
    <div>
      <h2 v-if="fetchedResults && tooltipsForSport && tooltipsForSport.length === 0">
        No tooltips exist for sport
      </h2>
    </div>
    <div v-if="fetchedResults" class="c-table-wrapper l-tooltips__table">
      <base-table-component :prop-data="tooltipsForSportTableData">
        <template v-slot:action>
          <div class="header-table-section">
            {{ sport.rowData.Sport }}
          </div>
          <div class="add-button">
            <div v-if="fetchedResults" class="c-search">
              <md-field class="u-width-m u-mb-xs u-mr-md">
                <label>Search</label>
                <md-input v-model="searchQuery" @input="handleSearch" />
              </md-field>
            </div>
            <div
              v-if="canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.TOOLTIPS.key}`)"
              class="add-section"
            >
              <img src="../../../../../../../assets/add-button.svg" class="add-button__new" alt="" @click="createTooltips()"> <span>Add new tooltip</span>
            </div>
          </div>
        </template>
        <template v-slot:default="slotProps">
          <span class="c-table__btn-wrap">
            <generic-button
              icon
              transparent
              text-inherit
              :disabled="!canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.TOOLTIPS.key}`)"
              @click="editTooltips(slotProps.selected)"
            >
              <md-tooltip md-direction="top">
                Edit sport tooltips
              </md-tooltip>
              <img src="../../../../../../../assets/edit-button.svg" alt="">
            </generic-button>
          </span>
          <span class="c-table__btn-wrap">
            <generic-button
              icon
              transparent
              text-inherit
              :disabled="!canWrite(`${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.TOOLTIPS.key}`)"
              @click="openDeleteModal(slotProps.selected)"
            >
              <md-tooltip md-direction="top">
                Delete sport tooltips
              </md-tooltip>
              <img src="../../../../../../../assets/delete-button.svg" alt="">
            </generic-button>
          </span>
        </template>
      </base-table-component>
      <template v-if="searchQuery && (totalItems === 0)">
        <md-empty-state
          md-icon="announcement"
          md-label="No Data"
        />
      </template>
    </div>

    <paginate
      v-if="totalPages > 1"
      v-model="currentPage"
      :page-count="totalPages"
      :prev-text="'<'"
      :next-text="'>'"
      :page-range="20"
      :container-class="'c-pagination'"
      :prev-link-class="'md-button prev md-elevation-1'"
      :next-link-class="'md-button next md-elevation-1'"
      :page-link-class="'md-button md-elevation-1'"
      :active-class="'active'"
      :disabled-class="'disabled'"
    />
  </div>
</template>

<script>
import GenericButton from '@/shared/buttons/GenericButton';
import BaseTableComponent from '@/shared/table/BaseTableComponent';
import {tooltipsService} from '@/services/tooltips';
import EditTooltipsModal from '../edit';
import {responseService} from '@/services/response';
import {mapActions} from 'vuex';
import DeleteModalComponent from '@/shared/delete-modal/DeleteModalComponent.vue';
import { wordService } from '@/services/words';
import each from 'lodash/each';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {PERMISSION_NAMES} from "@/const/online-permissions";

export default {
    name: 'SportTooltips',
    itemsPerPage: 10,

    components: {
      GenericButton,
      BaseTableComponent,
    },

  mixins: [onlinePermissionsMixin],

    props: {
      website: {
        type: Object,
        default: null,
      },
      sport: {
        type: Object,
        default: null
      },
      goBack: {
        type: Function,
        default: () => {}
      },
      languages: {
        type: Array,
        default: () => []
      },
      tooltipSection: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        searchQuery: '',

        currentPage: 1,
        totalPages: 1,
        totalItems: 0,
        itemsPerPage: 10,

        fetchedResults: false,
        tooltipsForSport: [],
        updateData: {},
        listOfPhraseKeys: [],
        listOfTemplateIds: [],
        translations: {},
      }
    },

    computed: {
      PERMISSION_NAMES() {
        return PERMISSION_NAMES
      },
      tooltipsForSportTableData() {
        if (!this.tooltipsForSport) {
          return [];
        }

        const currentPage = this.currentPage || 1;
        const unfilteredTooltips = this.tooltipsForSport.map((tooltipForSport, index) => ({
          tooltipIndex: index,
          tooltipForSport: tooltipForSport,
          rowData: {
            'Phrase key': tooltipForSport.phrase_key,
            'Template Ids': tooltipForSport.template_ids.join(', '),
          },
        }))

        return this.filterTooltipsForSport(unfilteredTooltips)
          .slice((currentPage - 1) * this.itemsPerPage, currentPage * this.itemsPerPage);
      },
    },

    async created() {
      this.getTooltipsForSport(true);
    },

    methods: {
      ...mapActions("response", ["handleSuccess", "setGlobalError", "resetError"]),

      async handleCreatedTooltip() {
        await this.getTooltipsForSport();
        this.currentPage = this.totalPages;
      },

      async getTooltipsForSport(isInit = false) {
        try {
          const tooltipsForSportResponse = await tooltipsService.getSingleSportTooltipsBySportId(this.sport.sportWithTooltip.sportId)
          this.updateData = tooltipsForSportResponse.data;
          this.tooltipsForSport = JSON.parse(tooltipsForSportResponse.data.tooltips);
          this.fetchedResults = true;
          this.listOfPhraseKeys = [];
          this.listOfTemplateIds = [];

          each(this.tooltipsForSport, (tooltip) => {
            this.listOfPhraseKeys.push(tooltip.phrase_key);
            this.listOfTemplateIds.push(...tooltip.template_ids);
          })

          this.handlePaginationParameters()

          if (!isInit || this.tooltipsForSport.length) {
            return;
          }

          this.createTooltips();
        } catch (error) {
          this.goBack();
          this.setGlobalError(responseService.getErrorMessage(error.data.message));
        }
      },

      async editTooltips(tooltip) {
        this.$modal.show(
          EditTooltipsModal,
          {
            fetchedTooltip: {
              phrase_key: tooltip.tooltipForSport.phrase_key,
              template_ids: [...tooltip.tooltipForSport.template_ids],
              tooltips: [...tooltip.tooltipForSport.tooltips],
            },
            handleSuccessCallback: this.getTooltipsForSport,
            allTooltips: this.tooltipsForSport,
            tooltipIndex: tooltip.tooltipIndex,
            sportId: this.sport.sportWithTooltip.sportId,
            websiteIds: this.sport.sportWithTooltip.websiteIds,
            listOfPhraseKeys: this.listOfPhraseKeys,
            listOfTemplateIds: this.listOfTemplateIds,
            languages: this.languages,
            tooltipSection: this.tooltipSection
          },
          { height: 'auto', scrollable: true, width: '50%' }
        );
      },

      createTooltips() {
        this.$modal.show(
          EditTooltipsModal,
          {
            handleSuccessCallback: this.handleCreatedTooltip,
            allTooltips: this.tooltipsForSport,
            sportId: this.sport.sportWithTooltip.sportId,
            websiteIds: this.sport.sportWithTooltip.websiteIds,
            listOfPhraseKeys: this.listOfPhraseKeys,
            listOfTemplateIds: this.listOfTemplateIds,
            languages: this.languages,
            tooltipSection: this.tooltipSection
          },
          { height: 'auto', scrollable: true, width: '50%' }
        );
      },

      async deleteTooltipForSport(tooltip) {
        try {
          await tooltipsService.deleteTooltips({
            sportId: this.sport.sportWithTooltip.sportId,
            phraseKey: tooltip.tooltipForSport.phrase_key
          })
          await this.handleDeleteTranslationsForTooltip(tooltip);
          this.handleSuccess({ message: 'Successfully deleted Tooltip' })
        } catch (error) {
          this.setGlobalError(responseService.getErrorMessage(error.data?.message));
        }
      },

      openDeleteModal(tooltip) {
        this.$modal.show(
          DeleteModalComponent,
          {
            deleteMsg: tooltip.tooltipForSport.phrase_key,
            deleteMsgAlert: 'It will be deleted on all websites.',
            propData: tooltip,
            deleteFunction: this.deleteTooltipForSport,
            getDataFunction: this.getTooltipsForSport,
            pageData: {}
          },
          { height: "auto", scrollable: false, width: "400px" }
        );
      },

      handlePaginationParameters(totalItemsCount = null) {
        const tooltipsCount = totalItemsCount !== null ? totalItemsCount : this.tooltipsForSport.length;

        this.totalPages = Math.ceil(tooltipsCount / this.itemsPerPage)
        this.totalItems = tooltipsCount;

        if (this.currentPage > this.totalPages) {
          this.currentPage = this.totalPages;
        }
      },

      handleSearch() {
        const filteredTooltips = this.filterTooltipsForSport(this.tooltipsForSport);
        this.handlePaginationParameters(filteredTooltips.length)
      },

      async fetchTranslations(term, currentPage = 1, limit = 200) {
        return await wordService.getOnlineAll(currentPage, limit, 'tooltips', term).then(response => {
          const translations = response.data.items.filter((single) => {
            let r = new RegExp(`^${term}$`);
            return single.key.match(r) || (includes(single.key, `_${term}_`))
          });
          return translations;
        })
      },

      deleteWord(id) {
        return wordService.deleteOnline(id);
      },

      getTranslationPhraseKeyFromSelectionAndTooltip(selection, tooltip) {
        return `${this.sport.sportWithTooltip.sportId}_${tooltip.tooltipForSport.phrase_key}_${selection}`
      },

      deleteTranslationByPhraseKey(selection) {
        const word = this.translations[this.getTranslationPhraseKeyFromSelection(selection)] ||
          find(this.listOfTemporaryWords, tempWord => tempWord.word === this.getTranslationPhraseKeyFromSelection(selection));
        const id = word?.wordId || word?.id;
        if (id) {
          this.deleteWord(id);
        }
      },

      async handleDeleteTranslationsForTooltip(tooltip) {
        const translations = await this.fetchTranslations(tooltip.tooltipForSport.phrase_key);

        if (!translations.length) {
          return;
        }

        each(translations, translation => {
          wordService.deleteOnline(translation.id);
        })
      },

      filterTooltipsForSport(unfilteredTooltips) {
        return filter(unfilteredTooltips, tooltipForSport => {
          if (tooltipForSport.rowData) {
            return tooltipForSport.tooltipForSport.phrase_key.toLowerCase().includes(this.searchQuery.toLowerCase())
                || tooltipForSport.tooltipForSport.template_ids.join(',').includes(this.searchQuery)
          }

          return tooltipForSport.phrase_key.toLowerCase().includes(this.searchQuery.toLowerCase())
            || tooltipForSport.template_ids.join(',').includes(this.searchQuery)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.c-search {
  margin-bottom: 16px;
}
.c-sport-with-tooltip-header {
  border-bottom: 2px solid gray;
  margin-bottom: 5px;
}
</style>
