<template>
  <div class="c-config-input-type c-config-input-type--column">
    <div class="c-config-input-info">
      <label>{{ input.label }}</label>
      <p>{{ input.description }}</p>
    </div>

    <div class="c-table-wrapper c-table--online-websites c-config-column-content">
      <base-table-component :prop-data="tableData">
        <template v-slot:action>
          <div class="header-table-section">
            Betradar
          </div>
          <div class="add-button">
            <div>
              List of banners
            </div>
            <div v-if="!disabled" class="add-section">
              <img src="../../assets/add-button.svg" class="add-button__new" alt="" @click="createOrUpdateBetradarConfigurationItem()"><span>Add new betradar sport</span>
            </div>
          </div>
        </template>
        <template v-slot:default="slotProps">
          <span class="c-table__btn-wrap">
            <generic-button
              icon
              transparent
              text-inherit
              :disabled="disabled"
              @click="createOrUpdateBetradarConfigurationItem(slotProps.selected.betradarConfigurationItem)"
            >
              <md-tooltip md-direction="top"> Edit </md-tooltip>
              <img src="../../assets/edit-button.svg" alt="">
            </generic-button>
          </span>

          <span class="c-table__btn-wrap">
            <generic-button
              icon
              transparent
              text-inherit
              :disabled="disabled"
              @click="openDeleteModal(slotProps.selected.betradarConfigurationItem)"
            >
              <md-tooltip md-direction="top"> Delete </md-tooltip>
              <img src="../../assets/delete-button.svg" alt="">
            </generic-button>
          </span>
        </template>
      </base-table-component>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash'
import GenericButton from '../buttons/GenericButton'
import BaseTableComponent from '@/shared/table/BaseTableComponent'
import CreateBetradarConfigurationItemModal from './modals/betradar-tracker'
import DeleteModalComponent from '@/shared/delete-modal/DeleteModalComponent'

export default {
  name: 'BetradarTrackerFormField',

  components: {
    GenericButton,
    BaseTableComponent,
  },

  props: {
    input: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
  },

  data() {
    return {
      inputValues: [],
    }
  },

  computed: {
    defaultInputValue() {
      try {
        return JSON.parse(get(this.input, 'defaultValue', []))
      } catch (e) {
        return []
      }
    },

    tableData() {
      const betradarConfigurationItems = [];

      this.inputValues.forEach(inputValue => {
        betradarConfigurationItems.push({
          betradarConfigurationItem: inputValue,
          rowData: {
            'sport': inputValue.sportName,
            'sport ID': inputValue.sportId,
            'is enabled on live event': inputValue.isLiveMatchEnabled,
            'is enabled on standard event': inputValue.isStandardMatchEnabled
          }
        });
      });

      return betradarConfigurationItems;
    }
  },

  created() {
    this.inputValues = get(this.input, 'value', this.defaultInputValue) || []
  },

  methods: {
    createOrUpdateBetradarConfigurationItem(betradarConfigurationItem) {
      if (!betradarConfigurationItem) {
        betradarConfigurationItem = {
          isLiveMatchEnabled: true,
          isStandardMatchEnabled: true
        }
      }

      this.$modal.show(
        CreateBetradarConfigurationItemModal,
        {
          betradarConfigurationItem,
          successFunction: this.addOrUpdateBetradarConfigurationItem
        },
        { height: "auto", scrollable: true }
      );
    },

    openDeleteModal(selected) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: selected.sportName,
          propData: selected.sportId,
          deleteFunction: this.deleteBetradarConfigurationItem,
        },
        { height: "auto", scrollable: false, width: "400px" }
      );
    },

    addOrUpdateBetradarConfigurationItem(betradarConfigurationItem) {
      const oldBetradarConfigurationItemIndex = this.inputValues.findIndex(inputValue => inputValue.sportId === betradarConfigurationItem.sportId);

      if (oldBetradarConfigurationItemIndex !== -1) {
        this.inputValues.splice(oldBetradarConfigurationItemIndex, 1, betradarConfigurationItem);
      } else {
        this.inputValues.push(betradarConfigurationItem);
      }

      this.setValue();
    },

    deleteBetradarConfigurationItem(sportId) {
      this.inputValues = this.inputValues.filter(inputValue => inputValue.sportId !== sportId);
      this.setValue();
    },

    setValue() {
      this.$emit('update', {
        value: JSON.stringify(this.inputValues),
        isValid: this.isValid()
      })
    },

    isValid() {
      return true;
    },
  },
}
</script>
<style lang="scss">
.c-table__cell {
  width: 25%!important;
}

.c-table__cell:first-child {
  width: 200px!important;
}
</style>
