<template>
  <div>
    <div class="c-form-wrapper u-overflow-auto">
      <form class="c-form">
        <div>
          <div class="c-form__avatar-wrapper">
            <div :class="[{ 'c-form-group--disabled' : !betshopLogoEnabled }]">
              <div class="c-form__betshop-avatar">
                <div :class="['c-avatar', { 'u-pointer-none': !hasWritePermision }]">
                  <img
                    v-if="betshopLogoUrl || previewLogoUrl"
                    class="c-avatar__img"
                    :src="getLogoImgSrc"
                    alt="betshop-logo"
                  >
                  <input type="file" :disabled="!hasWritePermision" class="c-avatar__input" @change="handleBetshopLogoChange($event.target.files)">
                  <generic-button
                    class="c-avatar__button"
                    variation="red"
                    :disabled="!hasWritePermision || !logoToUpload"
                    @click="handleBetshopLogoUpload"
                  >
                    Upload
                  </generic-button>
                </div>
              </div>
            </div>
            <div class="c-form__checkbox u-mr-0">
              <md-checkbox v-model="betshopLogoEnabled" :disabled="!hasWritePermision" class="c-form-checkbox__checkmark" /><span>Enable Configuration </span>
            </div>
          </div>
          <generic-form-group v-if="betshop && betshop.contactStreet !== 'false'" :disabled="true" label="Betshop address: ">
            <md-field
              class="c-input c-input--icon"
            >
              <md-input
                v-if="betshop.contactStreet"
                v-model="betshop.contactStreet"
                name="Betshop address"
              />
            </md-field>
          </generic-form-group>
          <div>
            <div
              v-for="configuration in generalSettingsConfiguration"
              :key="configuration.id"
              class="c-form__row"
            >
              <generic-form-group 
                v-if="(configuration.section === 'betshop' || configuration.section === 'pages') && apiSelectedConfigurationsData[findIndexByKey(configuration)].key !== 'delete_interval_licence_key'" 
                :disabled="apiSelectedConfigurationsData[findIndexByKey(configuration)] && !apiSelectedConfigurationsData[findIndexByKey(configuration)].enabled" 
                :label="configuration.label"
              >
                <multiple-selections
                  v-if="configuration.type === 'multiple' && apiSelectedConfigurationsData[findIndexByKey(configuration)]"
                  :id="configuration.label"
                  :label="allPagesEnabled(configuration) ? 'All pages are enabled.' : ''"
                  :name="configuration.label"
                  :disabled="!hasWritePermision"
                  :selected-text="configuration.label"
                  :selection-data="configuration.values"
                  :default-value="apiSelectedConfigurationsData && apiSelectedConfigurationsData[findIndexByKey(configuration)] ? apiSelectedConfigurationsData[findIndexByKey(configuration)].value : configuration.defaultValue"
                  @clicked="(value) => updateArrayConfigValues(apiSelectedConfigurationsData[findIndexByKey(configuration)], value)"
                />
                <md-field
                  v-if="configuration.type === 'input'"
                  class="c-input c-input--icon"
                >
                  <md-input
                    v-if="apiSelectedConfigurationsData[findIndexByKey(configuration)]"
                    v-model="apiSelectedConfigurationsData[findIndexByKey(configuration)].value"
                    :disabled="!hasWritePermision"
                    :name="configuration.label"
                    :type="(typeof configuration.defaultValue)"
                  />
                </md-field>
                <div
                  v-if="configuration.type === 'checkbox'"
                  class="c-input c-input--icon"
                >
                  <md-checkbox 
                    v-model="apiSelectedConfigurationsData[findIndexByKey(configuration)].value" 
                    class="c-form-checkbox__checkmark u-margin-top-20"
                  />
                </div>
              </generic-form-group>
              <div v-if="apiSelectedConfigurationsData[findIndexByKey(configuration)].key !== 'delete_interval_licence_key'" class="c-form__checkbox">
                <md-checkbox 
                  v-if="apiSelectedConfigurationsData[findIndexByKey(configuration)]" 
                  v-model="apiSelectedConfigurationsData[findIndexByKey(configuration)].enabled" 
                  :disabled="!hasWritePermision" 
                  class="c-form-checkbox__checkmark"
                />
                <span>Enable Configuration </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <generic-button
        variation="red"
        :disabled="!hasWritePermision"
        @click="updateSettings"
      >
        Update
      </generic-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import get from 'lodash/get';

import GenericFormGroup from '../../../shared/form-group/GenericFormGroup';
import GenericButton from '../../../shared/buttons/GenericButton';
import MultipleSelections from "../../../shared/multiple-selection/MultipleSelection";
import { configurationsService } from '../../../services/configurations';
import { companyService } from '../../../services/company';
import { checkFileExtensions } from '../../../const/validator';
import { logoFileExtensions } from '../../../const/index';
import config from '../../../../config/local'
import isEmpty from 'lodash/isEmpty';
import { hasWritePermission } from '../../../helpers/permission';

export default {
  name: 'GeneralSettings',

  components: {
    GenericFormGroup,
    GenericButton,
    MultipleSelections,
  },

  props: {
    betshop: {
      type: Object,
      default: () => ({
        id: '',
      })
    },
    apiConfigurationsData: {
      type: Array,
      default: () => ([])
    },
    apiSelectedConfigurationsData: {
      type: Array,
      default: () => ([])
    }
  },

  data() {
    return {
      previewLogoUrl: '',
      logoToUpload: null,
      logoUploadFileExtensions: logoFileExtensions,
      logoFileError: null,
      betshopLogoEnabled: this.isBetshopLogoEnabled,
      hasWritePermision: false
    };
  },

  computed: {
    ...mapGetters('company', ['getBetshopLogo']),
    betshopLogoUrl() {
      const logoUrl = get(this.getBetshopLogo, 'url');

      return logoUrl && `${config.BASE_URL}/${logoUrl}`;
    },

    getLogoImgSrc() {
      return this.previewLogoUrl ? this.previewLogoUrl : this.betshopLogoUrl
    },

    isBetshopLogoEnabled() {
      return get(this.getBetshopLogo, 'enabled') || false;
    },

    generalSettingsConfiguration() {
      return this.apiConfigurationsData.filter(conf => conf.section === 'betshop' || conf.section === 'pages')
    },

    isLogoCheckboxDisabled() {
      return !this.betshopLogoUrl && !this.logoToUpload
    },
  },

  async created() {
    await this.getBetshopLogoFromBe(this.$route.params.betshopId);
    this.betshopLogoEnabled = this.isBetshopLogoEnabled;
    this.checkPermissions();
  },

  methods: {
    ...mapActions('response', ['handleSuccess', 'setGlobalError', 'handleError']),
    ...mapActions('company', ['getBetshopLogoFromBe']),
    ...mapGetters('account', ['user', 'userHierarchyWriteableSections']),

    updateArrayConfigValues(array, value) {
      array.value = value;
    },

    async updateSettings() {
      let snackMessage = "Successfuly updated configurations! ";

      await configurationsService.updateMultipleBetshopConfigurations(
        get(this.$route, 'params.betshopId'),
        this.apiSelectedConfigurationsData.map((config) => {
          return {
            ...config,
            value: JSON.stringify(get(config, 'value')),
          }
        })
      );

      if (this.getBetshopLogo.id) {
        this.updateBetshopLogo();
      } else if (this.betshopLogoEnabled) {
        snackMessage+= "In order to enable logo configurations, first upload logo image.";
      }
      this.$emit('canChangeTab', false);
      this.handleSuccess({ message: snackMessage });
    },
    
    handleBetshopLogoChange(files) {
      this.validateLogoFile(files.item(0));

      if (this.logoFileError) {
        this.setGlobalError('Only image files are alowed.');
        return;
      }

      this.logoToUpload = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(this.logoToUpload);
      reader.onload = (event) => (this.previewLogoUrl = event.target.result);
      this.$emit('canChangeTab', true);
    },

    async handleBetshopLogoUpload() {
      try {
        this.logoToUpload && await this.uploadBetshopLogo();
        this.getBetshopLogoFromBe(this.$route.params.betshopId);
        this.handleSuccess({ message: `Betshop logo ${this.logoToUpload ? 'save' : 'updated'} successfully.` });
        this.$emit('canChangeTab', false);
      } catch (error) {
        this.setGlobalError(error.data.message);
      }
    },

    async updateBetshopLogo() {
      await companyService.updateBetshopLogo(
        this.$route.params.betshopId,
        this.betshopLogoEnabled,
      );
    },

    async uploadBetshopLogo() {
      const formData = new FormData();
      formData.append('file', this.logoToUpload, this.logoToUpload.name);

      await companyService.saveBetshopLogo(
        this.$route.params.betshopId,
        formData,
        this.betshopLogoEnabled,
      );
    },

    validateLogoFile(value) {
      this.logoFileError = checkFileExtensions(
        value,
        this.logoUploadFileExtensions
      );
    },

    findIndexByKey(configuration) {
      return this.apiSelectedConfigurationsData.findIndex(
        (config) => config.configurationId === configuration.id
      );
    },

    allPagesEnabled(configuration) {
      return isEmpty(this.apiSelectedConfigurationsData[this.findIndexByKey(configuration)].value)
    },

    checkPermissions() {
      this.hasWritePermision = hasWritePermission(
        this.user(),
        this.userHierarchyWriteableSections(),
        'settings-betshop'
      );
    }
  },
};
</script>
