<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link c-breadcrumbs__home"
          >
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Betshop Versions</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <div class="u-flex-row-end u-ph-md">
        <!-- TODO add text-->
        <h5 class="u-mb-xs">
          Non updated versions
        </h5>
        <div class="c-search u-ml-auto u-mr-md">
          <md-field
            md-inline
            class="u-width-m u-mb-xs"
          >
            <label>Search</label>
            <md-input
              v-model="term"
              @keydown.enter="search"              
            />
            <span @click="search()">
              <md-icon>search</md-icon>
            </span>
          </md-field>
        </div>
      </div>
      <div class="c-table-wrapper">
        <div class="c-table c-table--betshop-versions">
          <div class="c-table__head">
            <div class="c-table__cell">
              Company name
            </div>
            <div class="c-table__cell">
              Market name
            </div>
            <div class="c-table__cell">
              Betshop name
            </div>
            <div class="c-table__cell">
              Licence key
            </div>
            <div class="c-table__cell">
              Application version
            </div>
            <div class="c-table__cell">
              Updated At
            </div>
            <div class="c-table__cell">
              Mandatory Update
            </div>
          </div>
          <div class="c-table__body">
            <div v-for="licenceKey in licenceKeys" :key="licenceKey.id" class="c-table__row">
              <div class="c-table__cell">
                {{ licenceKey.companyName }}
              </div>
              <div class="c-table__cell">
                {{ licenceKey.marketName }}
              </div>
              <div class="c-table__cell">
                {{ licenceKey.betshopName }}
              </div>
              <div class="c-table__cell">
                {{ licenceKey.key }}
              </div>
              <div class="c-table__cell">
                {{ licenceKey.appVersion }}
              </div>
              <div class="c-table__cell">
                {{ formatDate(licenceKey.updatedAt) }}
              </div>
              <div class="c-table__cell">
                <div class="c-table__btn-wrap">
                  <generic-button
                    icon
                    transparent
                    text-inherit
                    @click="mandatoryUpdate(licenceKey)"
                  >
                    <md-tooltip md-direction="top">
                      Mandatory update
                    </md-tooltip>
                    <md-icon>upload</md-icon>
                  </generic-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <template v-if="!licenceKeys.length && isDataFetched">
        <md-empty-state
          md-icon="announcement"
          md-label="No Data"
        />
      </template>
      <paginate
        v-if="shouldShowPagination"
        :page-count="licenceKeysPaginationData.pageCount"
        :page-range="limitPerPage"
        :prev-text="'<'"
        :next-text="'>'"
        :click-handler="getLicenceKeys"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import { appManagerService } from "./../../services/app-manager";
import GenericButton from '../../shared/buttons/GenericButton';
import moment from 'moment';
import { betshopService } from './../../services/betshop';
import { mapActions } from "vuex";

export default {
  name: "BetshopVersions",
  components: {
    GenericButton
  },
  data() {
    return {
      licenceKeys: [],
      licenceKeysPaginationData: {},
      currentPage: 1,
      limitPerPage: 10,
      pageCount: 1,
      term: '',
      isDataFetched: false
    };
  },
  computed: {
    shouldShowPagination() {
      return this.licenceKeysPaginationData.totalItems > this.limitPerPage;
    },
  },
  async created() {
    this.getLicenceKeys(this.currentPage);
  },
  methods: {
    ...mapActions("response", ["setGlobalError", "handleSuccess"]),
    formatDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },
    getLicenceKeys(
      currentPage = this.currentPage,
      limit = this.limitPerPage,
    ) {
      appManagerService.getAllActiveBetshopVersions(currentPage, limit, this.term)
      .then(response => {
        this.currentPage = currentPage;
        this.licenceKeys = response.data.items;
        this.licenceKeysPaginationData = response.data;
        this.isDataFetched = true;
      });
    },
    search() {
      this.getLicenceKeys(1);
    },
    async mandatoryUpdate(licenceKey) {
      try {
        await betshopService.forceUpdate(licenceKey.key, licenceKey.betshopId);
        this.handleSuccess({ message: 'Betshop updated.' });
      } catch (error) {
        this.setGlobalError('Error updated betshop!');
      }
    },
  },
};
</script>

<style>
</style>
