<template>
  <div class="l-main__ctn-inner">
    <div class="u-flex-row-end u-ph-md">
      <div class="c-search u-ml-auto u-mr-md">
        <md-field md-inline class="u-width-m u-mb-xs">
          <label>Search</label>
          <md-input v-model="term" @keydown.enter="searchBetshops" />
          <span @click="searchBetshops()">
            <md-icon>search</md-icon>
          </span>
        </md-field>
      </div>
      <generic-button variation="red" @click="fetchBetShopsFromRest">
        Get betshops from rest
      </generic-button>
    </div>
    <div class="c-table-wrapper">
      <base-table-component :prop-data="getBetShopsForTable">
        <template v-slot:default="slotProps">
          <generic-button
            text-inherit
            icon
            transparent
            @click="goToBetshop(slotProps.selected.rowData.id)"
          >
            <md-tooltip md-direction="top">
              Go to betshop
            </md-tooltip>
            <md-icon>arrow_forward_ios</md-icon>
          </generic-button>
        </template>
      </base-table-component>
    </div>

    <template v-if="betshopsForTable">
      <md-empty-state md-icon="announcement" md-label="No Data" />
    </template>

    <paginate
      v-if="shouldShowPagination"
      :page-count="getBetshopPaginationData.pageCount"
      :page-range="pageInfo.LIMIT_PER_PAGE"
      :force-page="pageInfo.INITIAL_PAGE"
      :prev-text="'<'"
      :next-text="'>'"
      :click-handler="getDataPerPage"
      :container-class="'c-pagination'"
      :prev-link-class="'md-button prev md-elevation-1'"
      :next-link-class="'md-button next md-elevation-1'"
      :page-link-class="'md-button md-elevation-1'"
      :active-class="'active'"
      :disabled-class="'disabled'"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import BaseTableComponent from '../../shared/table/BaseTableComponent';
import GenericButton from '../../shared/buttons/GenericButton';
import { allBetshopsTabs } from '../../const';
import { PAGE_INFO } from '../../const/pagination';

export default {
  components: {
    BaseTableComponent,
    GenericButton,
  },
  data() {
    return {
      pageInfo: { ...PAGE_INFO },
      term: '',
    };
  },
  computed: {
    ...mapGetters('company', [
      'getBetShopsForTable',
      'getBetshopPaginationData',
    ]),
    ...mapState('company', ['isFetchedBetShops']),

    allBetshopsTabs() {
      return allBetshopsTabs;
    },
    betshopsForTable() {
      return !this.getBetShopsForTable.length && this.isFetchedBetShops;
    },
    shouldShowPagination() {
      return this.getBetshopPaginationData.totalItems > this.pageInfo.LIMIT_PER_PAGE;
    },
  },
  async created() {
    await this.fetchBetshops(this.$route.params.marketId);
  },

  destroyed() {
    this.clearBetshop();
  },

  methods: {
    ...mapActions('company', ['getBetShops', 'getBetShopsFromBe', 'getMarketLanguages', 'clearBetshop']),
    goToBetshop(betshopId) {
      this.$router.push({ name: 'betshop', params: { betshopId } });
    },

    async fetchBetshops(
      marketId,
      page = PAGE_INFO.INITIAL_PAGE,
      limit = PAGE_INFO.LIMIT_PER_PAGE,
      term = this.term
    ) {
      await this.getBetShops({
        marketId,
        page,
        limit,
        term,
      });
    },

    async fetchBetShopsFromRest() {
      await this.getBetShopsFromBe(this.$route.params.marketId);
      await this.getMarketLanguages({companyId: this.$route.params.companyId, marketId: this.$route.params.marketId});
    },

    getDataPerPage(
      currentPage = PAGE_INFO.INITIAL_PAGE,
      limit = PAGE_INFO.LIMIT_PER_PAGE,
      term = this.term
    ) {
      this.pageInfo.INITIAL_PAGE = currentPage;
      this.fetchBetshops(this.$route.params.marketId, currentPage, limit, term);
    },

    searchBetshops() {
      this.pageInfo.INITIAL_PAGE = PAGE_INFO.INITIAL_PAGE;
      this.pageInfo.LIMIT_PER_PAGE = PAGE_INFO.LIMIT_PER_PAGE;

      this.getDataPerPage(this.currentPage, this.limitPerPage, this.term);
    },
  },
};
</script>

<style></style>
