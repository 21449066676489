<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link c-breadcrumbs__home"
            @click="goToHome"
          >
            <md-icon>home</md-icon>
            <span>Home</span>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Configurations</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <md-tabs v-if="selectedTab.tab" class="c-tabs c-tabs--large" :md-active-tab="selectedTab.tab">
        <md-tab
          v-for="tab in configurationTabs"
          :id="tab.tab"
          :key="tab.tab"
          :md-label="tab.label"
          @click="changeActiveTab(tab)"
        />
      </md-tabs>
      <component :is="selectedTab.component" v-if="selectedTab.component" :tab="selectedTab.tab" />
    </div>
  </div>
</template>

<script>

import OnlineCustomPagesList from './components/custom-pages';
import OnlineCasinoCategoriesList from './components/casino-categories';
import OnlineTooltipsSportList from './components/tooltips';
import {PERMISSION_NAMES} from "@/const/online-permissions";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import BigJackpotConfiguration from './components/big-jackpot';

export default {
  name: 'OnlineConfigurations',
  components: {
    OnlineCustomPagesList,
    OnlineCasinoCategoriesList,
    OnlineTooltipsSportList,
    BigJackpotConfiguration
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      selectedTab: {
        tab: 'custom-pages',
        label: 'Custom pages',
        component: 'OnlineCustomPagesList',
      },
      configurationTabs: [
        {
          tab: 'custom-pages',
          label: 'Custom Pages',
          component: 'OnlineCustomPagesList',
          onlyDevelopers: true,
        },
        {
          tab: 'casino-categories',
          label: 'Casino Categories',
          component: 'OnlineCasinoCategoriesList',
          onlyDevelopers: true,
        },
        {
          tab: 'tooltips',
          label: 'Tooltips',
          component: 'OnlineTooltipsSportList',
          permission: `${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.TOOLTIPS.key}`
        },
        {
          tab: 'big-jackpot',
          label: 'Big Jackpot',
          component: 'BigJackpotConfiguration',
          permission: `${PERMISSION_NAMES.CONFIGURATION.key}.${PERMISSION_NAMES.BIG_JACKPOT.key}`,
          onlyDevelopers: false,
        },
      ],
    };
  },

  created() {
    if (this.user.isDeveloper) {
      return;
    }

    this.configurationTabs = this.configurationTabs.filter(tab => {
      if (tab.onlyDevelopers) {
        return false;
      }

      return this.canRead(tab.permission)
    });

    this.selectedTab = this.configurationTabs[0];
  },

  methods: {
    changeActiveTab(tab) {
      this.selectedTab = tab;
    },

    goToHome() {
      this.$router.push({name: 'home'});
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
