import { apiService } from '../api'
import { map } from 'lodash';

const ENDPOINTS = {
  CREATE: '/language/save',
  UPDATE: '/language/update',
  ONLINE_CREATE: '/online-language/save',
  ONLINE_UPDATE: '/online-language/update',
  GET: '/language',
  ONLINE_GET: '/online-language',
  DELETE: '/language',
  ONLINE_DELETE: '/online-language',
  UPLOAD: '/import-csv',
  ONLINE_UPLOAD: '/online-import-csv',
  ONLINE_TOOLTIPS_UPLOAD: '/online-import-tooltips-translations-csv',
}

class LanguageService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data)
  }

  async update(data) {
    return await apiService.post(ENDPOINTS.UPDATE, data)
  }

  async onLineCreate(data) {
    return await apiService.post(ENDPOINTS.ONLINE_CREATE, data)
  }

  async onLineUpdate(data) {
    return await apiService.post(ENDPOINTS.ONLINE_UPDATE, data)
  }

  async getAllWithPagination(page, limit) {
    return await apiService.get(`${ENDPOINTS.GET}?page=${page}&limit=${limit}`)
  }

  async getOnLineAllWithPagination(page, limit) {
    return await apiService.get(`${ENDPOINTS.ONLINE_GET}?page=${page}&limit=${limit}`)
  }

  async getAllLanguages() {
    return await apiService.get(ENDPOINTS.GET)
  }

  async getAllOnlineLanguages() {
    return await apiService.get(ENDPOINTS.ONLINE_GET)
  }

  async delete(id) {
    return await apiService.delete(`${ENDPOINTS.DELETE}/${id}`);
  }

  async onlineDelete(id) {
    return await apiService.delete(`${ENDPOINTS.ONLINE_DELETE}/${id}`);
  }

  async uploadCSVFile(data, id) {
    return await apiService.upload(`${ENDPOINTS.UPLOAD}?id=${id}`, data);
  }

  async uploadOnlineCSVFile(data, id) {
    return await apiService.upload(`${ENDPOINTS.ONLINE_UPLOAD}?id=${id}`, data);
  }

  async uploadOnlineTooltipsTranslationsFile(data, id) {
    return await apiService.upload(`${ENDPOINTS.ONLINE_TOOLTIPS_UPLOAD}?languageId=${id}`, data);
  }

  formatDataForTable(response) {
    return map(response, data => {
      return {
        language: data,
        rowData: {
          name: data.name,
          code: data.code,
        }
      }
    });
  }
}

export const languageService = new LanguageService()
