<template>
  <div class="l-main u-ph-md u-pv-xs">
    <div>
      <h5 class="u-mb-md">
        Published <span class="u-text-capitalized">{{ type }}</span> version
      </h5>
      <div class="c-table c-table--versions c-table--versions--sm">
        <div class="c-table__head">
          <div v-for="field in publishedFields" :key="field" class="c-table__cell">
            {{ field }}
          </div>
        </div>
        <div class="c-table__body">
          <div v-if="currentPublishedApp" class="c-table__row">
            <div class="c-table__cell">
              {{ currentPublishedApp.name }}
            </div>
            <div class="c-table__cell">
              {{ currentPublishedApp.version }}
            </div>
            <div class="c-table__cell">
              {{ formatPublishedAppTime }}
            </div>
            <div class="c-table__cell">
              {{ getAssignedByEmail(currentPublishedApp) }}
            </div>

            <div class="c-table__cell">
              <div class="c-table__btn-wrap">
                <generic-button
                  icon
                  transparent
                  text-inherit
                  @click="downloadApp(currentPublishedApp)"
                >
                  <md-tooltip md-direction="top">
                    Download
                  </md-tooltip>
                  <md-icon>download</md-icon>
                </generic-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="u-mt-lg u-flex-full-height">
      <h5 class="u-mb-md">
        Approved <span class="u-text-capitalized">{{ type }}</span> version
      </h5>
      <div class="c-table c-table--versions">
        <div class="c-table__head">
          <div v-for="field in approvedFields" :key="field" class="c-table__cell">
            {{ field }}
          </div>
        </div>
        <div class="c-table__body">
          <div v-if="currentApprovedApp" class="c-table__row">
            <div class="c-table__cell">
              {{ currentApprovedApp.name }}
            </div>
            <div class="c-table__cell">
              {{ currentApprovedApp.version }}
            </div>
            <div class="c-table__cell">
              {{ formatApprovedAppTime }}
            </div>
            <div class="c-table__cell">
              {{ getAssignedByEmail(currentApprovedApp) }}
            </div>

            <div class="c-table__cell">
              <div class="c-table__btn-wrap">
                <generic-button icon transparent text-inherit @click="publishApp()">
                  <md-tooltip md-direction="top">
                    Publish
                  </md-tooltip>
                  <md-icon>done</md-icon>
                </generic-button>
                <generic-button
                  icon
                  transparent
                  text-inherit
                  @click="downloadApp(currentApprovedApp)"
                >
                  <md-tooltip md-direction="top">
                    Download
                  </md-tooltip>
                  <md-icon>download</md-icon>
                </generic-button>
                <generic-button
                  icon
                  transparent
                  text-inherit
                  @click="deleteApp()"
                >
                  <md-tooltip md-direction="top">
                    Delete
                  </md-tooltip>
                  <img src="../../../../assets/delete-button.svg" alt="">
                </generic-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { approvedFields, publishedFields } from '../../../../const/systems';
import GenericButton from '../../../../shared/buttons/GenericButton';
import ChooseUpdate from '../popups/choose-update';
import config from '../../../../../config/local'
import { appManagerService } from '../../../../services/app-manager';
import { mapActions } from 'vuex';
import moment from 'moment';
import { first } from 'lodash'

export default {
  name: "Published",

  components: {
    GenericButton
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      uploadProgress: 0,
      dataIsSubmiting: false,
      currentApprovedApp: null,
      currentPublishedApp: null,
      publishedFields: publishedFields,
      approvedFields: approvedFields
    }
  },

  computed: {
    formatApprovedAppTime() {
      return moment(this.currentApprovedApp?.updatedAt).format('DD/MM/YYYY HH:mm')
    },
    formatPublishedAppTime() {
      return moment(this.currentPublishedApp?.updatedAt).format('DD/MM/YYYY HH:mm')
    }
  },

  watch: {
    type() {
      this.fetchApp();
    }
  },

  async created() {
    await this.fetchApp();
  },

  methods: {
    ...mapActions('response', ['handleSuccess', 'setGlobalError']),

    async fetchApp() {
      try {
        const approvedApps = await appManagerService.getAppByStatus('approved', this.type);
        const publishedApps = await appManagerService.getAppByStatus('published', this.type);
        this.currentApprovedApp = approvedApps.data.length ? first(approvedApps.data) : null;
        this.currentPublishedApp = publishedApps.data.length ? first(publishedApps.data) : null;
      } catch (error) {
        this.setGlobalError(error.data.message);
      }
    },

    async downloadApp(app) {
      const link = document.createElement('a');
      link.setAttribute('download', app.name);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.setAttribute('href', `${config.BASE_DOWNLOAD_URL}/${app.downloadUrl}`);
      link.click();
      link.remove();
    },

    publishApp() {
      this.$modal.show(
        ChooseUpdate,
        {
          onUpdate: this.onUpdate,
          onMandatoryUpdate: this.onMandatoryUpdate,
          onMandatoryBlockingUpdate: this.onMandatoryBlockingUpdate
        },
        { height: "auto", scrollable: true }
      );
    },

    async onUpdate() {
      try {
        this.publishApplication('regular');
      } catch (error) {
        console.log(error, 'error')
        this.setGlobalError(error.data.message);
      }
    },

    async onMandatoryUpdate() {
      try {
        this.publishApplication('mandatory');
      } catch (error) {
        console.log(error, 'error')
        this.setGlobalError(error.data.message);
      }
    },

    async onMandatoryBlockingUpdate() {
      try {
        this.publishApplication('mandatory-blocking');
      } catch (error) {
        console.log(error, 'error')
        this.setGlobalError(error.data.message);
      }
    },

    async deleteApp() {
      try {
        await appManagerService.deleteAppData(this.currentApprovedApp.id)
        this.currentApprovedApp = null;
        this.handleSuccess({ message: 'App has been removed.' })
      } catch (error) {
        this.setGlobalError(error.data.message);
      }
    },

    async publishApplication(updateType) {
      await appManagerService.updateVersion(this.currentApprovedApp)
      this.handleSuccess({ message: 'App has been published.' })
      await appManagerService.notifyNewVersion([this.currentApprovedApp.platform], updateType, this.currentApprovedApp.version)
      this.currentApprovedApp = null;
      await this.fetchApp();
    },

    getAssignedByEmail(app) {
      return app?.updatedBy ? app.updatedBy.email : ''
    }
  },
};

</script>
