import get from 'lodash/get';
import { companyService } from '../../services/company'
import { configurationsService } from '../../services/configurations/index'

function getInitialState() {
  return {
    companies: [],
    markets: [],
    betshops: [],
    betshopLogo: null,
    companyPaginationData: {},
    betshopPaginationData: {},
    companyName: null,
    marketplaceName: null,
    marketName: null,
    marketLogo: null,
    marketsPaginationData: {},
    isFetchedCompanies: false,
    isFetchedMarkets: false,
    isFetchedBetShops: false,
    defaultMarketLanguage: null,
    availableMarketLanguages: [],
    currentMarketTicketTopSection: {},
    currentBetshopTicketTopSection: {},
    currentAllBetshopTicketTopSections: [],
    currentAllMarketTicketTopSections: [],
    currentAllBetshopTicketDescription: [],
    currentAllMarketTicketDescription: [],
    apiConfigurationsData: [],
    apiMarketConfigurationsData: [],
    apiBetshopConfigurationsData: [],
  }
}

const state = getInitialState()

const actions = {
  async getCompaniesFromRest({ commit, dispatch }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.getCompaniesFromRest();
      if (data.length === 0) {
        return
      }
      const response = await companyService.saveCompanies(data)
      commit('GET_COMPANIES_SUCCESS', response)
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }

  },

  async getCompanies({ commit, dispatch }, { page, limit, term }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const response = await companyService.getCompanies(page, limit, term)
      commit('GET_COMPANIES_SUCCESS', response)
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
    }
  },

  async getMarketsFromRest({ commit, dispatch }, marketId) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.getMarketsFromRest(marketId);
      if (data.length === 0) {
        return
      }
      const savedMarkets = await companyService.saveMarkets(data);
      commit('GET_MARKETS_SUCCESS',
        {
          companyId: marketId,
          markets: savedMarkets.data
        }
      )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }

  },

  async getMarkets({ commit, dispatch }, options) {
    try {
      commit('SET_IS_FETCHING_MARKETS', false)
      dispatch('response/setGlobalError', null, { root: true });

      const markets = await companyService.getMarketsByCompanyId(options)

      commit('GET_MARKETS_SUCCESS',
        {
          companyId: options.companyId,
          markets: markets.data
        }
      )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
    }
  },

  async getBetShopsFromBe({ commit, dispatch }, marketId) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.getBetshopsFromRest(marketId);
      if (data.length === 0) {
        return
      }
      const savedBetshops = await companyService.saveBetshops(data);

      commit('GET_BETSHOPS_SUCCESS',
        {
          marketId: marketId,
          betshops: savedBetshops.data.items,
          paginationData: {
            totalItems: savedBetshops.data.totalItems,
            pageCount: savedBetshops.data.pageCount
          }
        }
      )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async getMarketLanguages({ commit, dispatch }, params) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.getMarketLanguages(params.companyId, params.marketId);
      if (data.length === 0) {
        return
      }
      await companyService.saveLanguages(params.marketId, { language: data.payload.language, additionalLanguages: data.payload.additionalLanguages });
      commit('GET_LANGUAGES_SUCCESS',
        {
          defaultMarketLanguage: data.payload.language,
          additionalLanguages: data.payload.additionalLanguages
        }
      )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async getBetShops({ commit, dispatch }, { marketId, page, limit, term }) {
    try {
      commit('SET_IS_FETCHING_BETSHOPS', false)
      dispatch('response/setGlobalError', null, { root: true });

      const { data } = await companyService.getBetshopsByMarketId(marketId, page, limit, term);

      if (!data) {
        return;
      }

      commit('GET_BETSHOPS_SUCCESS',
        {
          marketId: marketId,
          betshops: data.items,
          paginationData: {
            totalItems: data.totalItems,
            pageCount: data.pageCount
          }
        }
      )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
    }
  },

  async getCompanyBredCrumps({ commit }, marketId) {
    const { data } = await companyService.getCompanyBredCrumps(marketId);
    if (!data) {
      return
    }
    commit('SET_COMPANY_NAME', data.name)
  },

  async getMarketBredCrumps({ commit }, marketId) {
    const { data } = await companyService.getMarketBredCrumps(marketId);
    if (!data) {
      return
    }
    commit('SET_MARKET_NAME', data.name)
    commit('SET_COMPANY_NAME', data.company.name)
  },

  async getMarketLogoFromBe({ commit, dispatch }, marketId) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.getMarketLogo(marketId);
      if (data.length === 0) {
        return
      }

      commit('SET_MARKET_LOGO', data)

    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async getBetshopLogoFromBe({ commit, dispatch }, betshopId) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.getBetshopLogo(betshopId);
      if (data.length === 0) {
        return
      }

      commit('SET_BETSHOP_LOGO', data)

    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async clearBetshopLogoFromBe({ commit }) {
    commit('CLEAR_BETSHOP_LOGO')
  },

  async saveMarketTicketFooterDescription({ dispatch }, { marketId, language, ticketFooter, ticketFooterCss }) {
    try {
      const { data } = await companyService.saveMarketTicketFooterDescription({ marketId, language, ticketFooter, ticketFooterCss });
      if (data) {
        dispatch('response/handleSuccess', { message: "Ticket footer description updated successfully." }, { root: true });
      }
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async saveBetshopTicketFooterDescription({ dispatch }, { betshopId, language, ticketFooter, ticketFooterCss, enabled }) {
    try {
      const { data } = await companyService.saveBetshopTicketFooterDescription({ betshopId, language, ticketFooter, ticketFooterCss, enabled });
      if (data) {
        dispatch('response/handleSuccess', { message: "Ticket footer description updated successfully." }, { root: true });
      }
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async fetchAllBetshopTicketTopSection({ commit, dispatch }, { betshopId }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.fetchAllBetshopTicketTopSection(betshopId);
      commit('SET_ALL_BETSHOP_TICKET_TOP_SECTION', data)

      return data;
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async fetchAllBetshopTicketDescription({ commit, dispatch }, { betshopId }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.fetchAllBetshopTicketDescription(betshopId);
      commit('SET_ALL_BETSHOP_TICKET_DESCRIPTION', data)

      return data;
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async fetchAllMarketTicketDescription({ commit, dispatch }, { marketId }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.fetchAllMarketTicketDescription(marketId);
      commit('SET_ALL_MARKET_TICKET_DESCRIPTION', data)

      return data;
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async fetchAllMarketTicketTopSection({ commit, dispatch }, { marketId }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const { data } = await companyService.fetchAllMarketTicketTopSection(marketId);
      commit('SET_ALL_MARKET_TICKET_TOP_SECTION', data)

      return data;
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async saveMarketTicketTopSection({ dispatch }, { marketId, language, ticketTopSection, ticketTopSectionCss, type }) {
    try {
      const { data } = await companyService.saveMarketTicketTopSection({ marketId, language, ticketTopSection, ticketTopSectionCss, type });
      if (data) {
        dispatch('response/handleSuccess', { message: "Ticket top section updated successfully." }, { root: true });
      }
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async saveBetshopTicketTopSection({ dispatch }, { betshopId, language, ticketTopSection, ticketTopSectionCss, enabled, type }) {
    try {
      const { data } = await companyService.saveBetshopTicketTopSection({ betshopId, language, ticketTopSection, ticketTopSectionCss, enabled, type });
      if (data) {
        dispatch('response/handleSuccess', { message: "Ticket top section updated successfully." }, { root: true });
      }
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with REST', { root: true });
    }
  },

  async fetchConfigurationsAndMarketConfigurations({ commit }, marketId) {
    const responseConf = await configurationsService.getConfigurations();
    const apiConfigurationsData = configurationsService.parseApiConfig(get(responseConf, 'data'));
    commit('SET_CONFIGURATION', { apiConfigurationsData })

    const responseMarketConf = await configurationsService.getByMarketId(marketId);
    const data = get(responseMarketConf, 'data');

    commit('SET_MARKET_CONFIGURATIONS', { 
      apiMarketConfigurationsData: configurationsService.prepareConfigurations('marketId', apiConfigurationsData, marketId, data) 
    })
  },

  async fetchConfigurationsAndBetshopConfigurations({ commit }, betshopId) {
    const responseConf = await configurationsService.getConfigurations();
    const apiConfigurationsData = configurationsService.parseApiConfig(get(responseConf, 'data'));
    commit('SET_CONFIGURATION', { apiConfigurationsData })

    const responseBetShopConf = await configurationsService.getByBetShopId(betshopId);
    const data = get(responseBetShopConf, 'data');

    commit('SET_BETSHOP_CONFIGURATIONS', {
      apiBetshopConfigurationsData: configurationsService.prepareConfigurations('betshopId', apiConfigurationsData, betshopId, data) 
    });
  },

  clearBetshop({ commit }) {
    commit('CLEAR_BETSHOPS_SUCCESS')
  },

  updateMarketConfigurations({ commit }, apiMarketConfigurationsData) {
    const parsedConfigurations = configurationsService.parseInstanceConfig(apiMarketConfigurationsData);

    commit('SET_MARKET_CONFIGURATIONS', { apiMarketConfigurationsData: parsedConfigurations })
  },

  updateBetshopConfigurations({ commit }, apiBetshopConfigurationsData) {
    const parsedConfigurations = configurationsService.parseInstanceConfig(apiBetshopConfigurationsData);

    commit('SET_BETSHOP_CONFIGURATIONS', { apiBetshopConfigurationsData: parsedConfigurations })
  }
}

const getters = {
  getCompaniesForTable: state => state.companies.map(company => {
    return {
      rowData: {
        id: company.id,
        name: company.name
      }
    }
  }),

  getFilteredMarketsForTable: state => param => {
    const foundMarket = state.markets.filter(market => market.companyId == param);

    if (!foundMarket) {
      return [];
    }

    return foundMarket.map((market) => {
      return {
        rowData: {
          id: market.id,
          name: market.name,
        }
      }
    })
  },

  getBetShopsForTable: state => {
    if (!state.betshops.length) {
      return [];
    }
    return state.betshops.map((betShop) => {
      return {
        rowData: {
          id: betShop.id,
          name: betShop.name,
        }
      }
    })
  },

  getAllCompanies: state => state.companies.map(company => {
    return {
      id: company.id,
      name: company.name,
      selected: false
    }
  }),

  isFetchedCompanies: state => state.isFetchedCompanies,

  getMarketsPaginationData: state => state.marketsPaginationData,

  getCompanyPaginationData: state => state.companyPaginationData,

  getBetshopPaginationData: state => state.betshopPaginationData,

  getMarketLogo: state => state.marketLogo,

  getBetshopLogo: state => state.betshopLogo,

  getDefaultMarketLanguage: state => state.defaultMarketLanguage,

  getAdditionalMarketLanguages: state => state.availableMarketLanguages,

  getCurrentMarketTicketTopSection: state => state.currentMarketTicketTopSection,

  getApiConfigurationsData: state => state.apiConfigurationsData,

  getApiMarketConfigurationsData: state => state.apiMarketConfigurationsData,

  getApiBetshopConfigurationsData: state => state.apiBetshopConfigurationsData,

  getAllBetshopTicketTopSection: state => state.currentAllBetshopTicketTopSections,

  getAllMarketTicketTopSection: state => state.currentAllMarketTicketTopSections,

  getAllBetshopTicketDescriptions: state => state.currentAllBetshopTicketDescription,

  getAllMarketTicketDescriptions: state => state.currentAllMarketTicketDescription,
}

const mutations = {
  GET_COMPANIES_SUCCESS(state, data) {
    state.companies = data.data.items
    state.companyPaginationData = {
      totalItems: data.data.totalItems,
      pageCount: data.data.pageCount
    }
    state.isFetchedCompanies = true
  },
  GET_MARKETS_SUCCESS(state, data) {
    state.isFetchedMarkets = true
    state.marketsPaginationData = {
      totalItems: data.markets.totalItems,
      pageCount: data.markets.pageCount
    }
    state.markets = data.markets.items;
  },
  GET_BETSHOPS_SUCCESS(state, data) {
    state.isFetchedBetShops = true
    state.betshopPaginationData = data.paginationData
    state.betshops = data.betshops
  },
  CLEAR_BETSHOPS_SUCCESS(state) {
    state.isFetchedBetShops = false
    state.betshopPaginationData = {}
    state.betshops = []
  },
  GET_LANGUAGES_SUCCESS(state, data) {
    state.defaultMarketLanguage = data.defaultMarketLanguage
    state.availableMarketLanguages = data.additionalLanguages
  },
  SET_IS_FETCHING_BETSHOPS(state, data) {
    state.isFetchedBetShops = data
  },
  SET_IS_FETCHING_MARKETS(state, data) {
    state.isFetchedMarkets = data
  },
  SET_COMPANY_NAME(state, data) {
    state.companyName = data
  },
  SET_MARKET_NAME(state, data) {
    state.marketName = data
  },
  SET_MARKET_LOGO(state, data) {
    state.marketLogo = data
  },
  SET_BETSHOP_LOGO(state, data) {
    state.betshopLogo = data
  },
  CLEAR_BETSHOP_LOGO(state, data) {
    state.betshopLogo = data
  },
  SET_MARKET_TICKET_DESCRIPTION(state, data) {
    state.currentMarketTicketDescription = data
  },
  SET_CONFIGURATION(state, { apiConfigurationsData }) {
    state.apiConfigurationsData = apiConfigurationsData;
  },
  SET_MARKET_CONFIGURATIONS(state, { apiMarketConfigurationsData }) {
    state.apiMarketConfigurationsData = apiMarketConfigurationsData;
  },
  SET_BETSHOP_CONFIGURATIONS(state, { apiBetshopConfigurationsData }) {
    state.apiBetshopConfigurationsData = apiBetshopConfigurationsData;
  },
  SET_ALL_BETSHOP_TICKET_TOP_SECTION(state, data) {
    state.currentAllBetshopTicketTopSections = data;
  },
  SET_ALL_MARKET_TICKET_TOP_SECTION(state, data) {
    state.currentAllMarketTicketTopSections = data;
  },
  SET_ALL_BETSHOP_TICKET_DESCRIPTION(state, data) {
    state.currentAllBetshopTicketDescription = data;
  },
  SET_ALL_MARKET_TICKET_DESCRIPTION(state, data) {
    state.currentAllMarketTicketDescription = data;
  }
}

export const company = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
