import { apiService } from '../api'
import {  map } from 'lodash';

const ENDPOINTS = {
  CREATE: '/section/save',
  GET: '/sections',
  UPDATE: '/section/update',
  DELETE: '/section',
  ONLINE_CREATE: '/online-section/save',
  ONLINE_GET: '/online-sections',
  ONLINE_UPDATE: '/online-section/update',
  ONLINE_DELETE: '/online-section'
}

class SectionService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data)
  }

  async update(data) {
    return await apiService.put(ENDPOINTS.UPDATE, data)
  }

  async getAllSections(page, limit, shouldPaginate) {
    return await apiService.get(`${ENDPOINTS.GET}?page=${page}&limit=${limit}&shouldPaginate=${shouldPaginate}`);
  }

  async deleteSection(id) {
    return await apiService.delete(`${ENDPOINTS.DELETE}/${id}`);
  }

  async onlineCreate(data) {
    return await apiService.post(ENDPOINTS.ONLINE_CREATE, data)
  }

  async onlineUpdate(data) {
    return await apiService.put(ENDPOINTS.ONLINE_UPDATE, data)
  }

  async onlineGetAllSections(page, limit, shouldPaginate) {
    return await apiService.get(`${ENDPOINTS.ONLINE_GET}?page=${page}&limit=${limit}&shouldPaginate=${shouldPaginate}`);
  }

  async onlineDeleteSection(id) {
    return await apiService.delete(`${ENDPOINTS.ONLINE_DELETE}/${id}`);
  }

  formatDataForTable(response) {
    return map(response, data => {
      return {
        word: {
          key: data.key,
          title: data.title,
        },
        rowData: {
          key: data.key,
          title: data.title,
        }
      }
    });
  }

  formatSectionData(selectedSection) {
    if (selectedSection) {
      return selectedSection;
    }

    return { key: null, title: null };
  }

  prepareSectionFromResponse(items) {
    const sections = [];
  
    items.forEach(section => {
      sections.push({
        rowData: {
          key: section.key,
          title: section.title,
        }, 
        word: section,
      });
    });

    return sections;
  }
}

export const sectionService = new SectionService()
