<template>
  <div class="c-table">
    <template v-if="tableData && tableData[0]">
      <slot name="action" />
      <div class="c-table__head">
        <template v-for="(key, i) in getKeys(tableData[0].rowData)">
          <div
            v-if="key !== 'permissions'"
            :key="i"
            :class="['c-table__cell', sortColumnDirection && sortColumnActive === key ? `c-table__cell-${sortColumnDirection}` : '']"
            @click="sortable && handleSort(key)"
          >
            <span>{{ key === 'phrase' ? 'key' : key }}</span>
          </div>
        </template>
        <div
          class="c-table__cell"
        >
          {{ actionLabel }}
        </div>
      </div>
      <div class="c-table__body">
        <draggable v-model="tableData" :disabled="!draggable" @start="drag=true" @end="drag=false" @sort="sortHandler">
          <div v-for="(data, index) in tableData" :key="index" :class="['c-table__row', { 'c-table__row--draggable': draggable }]">
            <template v-for="(key, i) in getKeys(data.rowData)">
              <div v-if="key !== 'permissions'" :key="i" class="c-table__cell">
                <span v-if="i === 0 && draggable" class="u-mr-xs"><md-icon>drag_indicator</md-icon></span>{{ data.rowData[key] }}
              </div>
            </template>
            <div class="c-table__cell">
              <slot name="default" :selected="data" />
            </div>
          </div>
        </draggable>
      </div>
    </template>
    <template v-else>
      <div v-if="!isTicketBanner" class="c-table__head" />
      <slot v-if="isTicketBanner" name="action" />
      <div class="c-table__body">
        NO RESULTS
      </div>
    </template>
  </div>
</template>

<script>
import { keys } from 'lodash';
import draggable from 'vuedraggable'

export default {
  name: 'BaseTableComponent',

  components: {
    draggable,
  },

  props: {
    propData: {
      type: Array,
      required: true,
    },
    isTicketBanner: {
      type: Boolean,
      default: false
    },
    sortable: {
      type: Boolean,
      required: false,
      default: false,
    },
    actionLabel: {
      type: String,
      required: false,
      default: ''
    },
    draggable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      tableData: [],
      sortColumnActive: '',
      sortColumnDirection: '',
      sortingDirections: ['asc', 'desc', ''],
    }
  },

  watch: {
    propData: {
      handler(newValue) {
        this.tableData = newValue;
      },
      deep: true
    }
  },

  created() {
    this.tableData = this.propData;
  },

  methods: {
    sortHandler() {
      this.$emit('dragSort', this.tableData)
    },

    getKeys(data) {
      return keys(data);
    },

    handleSort(key) {
      let directionIndex = 0;

      if (this.sortColumnActive === key) {
        directionIndex = this.sortingDirections.findIndex(direction => {
          return direction === this.sortColumnDirection
        });

        directionIndex++;

        if (directionIndex >= this.sortingDirections.length) {
          directionIndex = 0;
        }
      }

      this.sortColumnActive = key;
      this.sortColumnDirection = this.sortingDirections[directionIndex]

      this.$emit('sort', key, this.sortColumnDirection)
    }
  },
};
</script>
