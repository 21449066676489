<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="md-title u-typography-color">
        Export CSV
      </h4>
    </template>

    <template v-slot:body>
      <md-field>
        <md-select
          id="language"
          v-model="selectedLanguage"
          name="language"
          placeholder="Select language"
        >
          <md-option
            v-for="language in languages"
            :key="language.id"
            :value="language.id"
          >
            {{ language.code }}
          </md-option>
        </md-select>
      </md-field>
    </template>

    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close()"
        >
          Close
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="exportData()"
      >
        Export
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import BaseModalComponent from '../../../../../shared/modal/BaseModalComponent'
import GenericButton from "../../../../../shared/buttons/GenericButton";
import { exportService } from '../../../../../services/export/index'

export default {
  components: {
    BaseModalComponent,
    GenericButton
  },
  props: {
    languages: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedLanguage: ''
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    exportData() {
      exportService.downloadFile(
        this.selectedLanguage,
        this.languages.find(l => l.id === this.selectedLanguage).code
      ).then(() => {
        this.close();
      });
    }
  },
};
</script>
