import {PERMISSION_NAMES} from "@/const/online-permissions";

export const betshopTabs = [
  {
    tab: 'general-settings',
    label: 'General settings',
    section: 'settings',
    subItems: [],
  },
  {
    tab: 'licence-keys',
    label: 'Licence keys',
    section: 'licenceKeys',
    subItems: [],
  },
  {
    tab: 'ticket-settings',
    label: "Ticket Settings",
    section: 'ticketSettings',
    subItems: [
      {
        tab: 'page-configurations',
        label: 'Settings',
        subSection: 'print-settings',
        subItems: [],
      },
      {
        tab: 'ticket-descriptions',
        label: 'Settings',
        subSection: 'ticket-descriptions',
        subItems: [],
      },
      {
        tab: 'ticket-top-section',
        label: 'Settings',
        subSection: 'ticket-top-section',
        subItems: [],
      }
    ],
  },
  {
    tab: 'shift-settings',
    label: "Shift Settings",
    section: 'shiftSettings',
    subItems: [],
  },
  {
    tab: 'disabled-pages',
    label: 'Disabled Pages',
    section: 'disabledPages',
    subItems: [],
  },
];

export const allBetshopsTabs = [
  {
    tab: 'all-betshops',
    label: 'Betshops',
    section: 'betShops',
    subItems: [],
  },
  {
    tab: 'page-configurations',
    label: 'Settings',
    section: 'settings',
    subItems: [],
  },
  {
    tab: 'ticket-settings',
    label: "Ticket Settings",
    section: 'ticketSettings',
    subItems: [
      {
        tab: 'page-configurations',
        label: 'Settings',
        subSection: 'print-settings',
        subItems: [],
      },
      {
        tab: 'ticket-descriptions',
        label: 'Settings',
        subSection: 'ticket-descriptions',
        subItems: [],
      },
      {
        tab: 'ticket-top-section',
        label: 'Settings',
        subSection: 'ticket-top-section',
        subItems: [],
      }
    ],
  },
  {
    tab: 'shift-settings',
    label: "Shift Settings",
    section: 'shiftSettings',
    subItems: [],
  },
  {
    tab: 'disabled-pages',
    label: 'Disabled Pages',
    section: 'disabledPages',
    subItems: [],
  },
];

export const manageAppTabs = [
  {
    tab: 'drivers',
    label: 'Drivers',
  }
];

export const ticketSettings = [
  {
    tab: 'print-ticket-settings',
    label: 'Print Settings',
    subSection: 'print-settings'
  },
  {
    tab: 'ticket-desciptions',
    label: 'Ticket Descriptions',
    subSection: 'ticket-descriptions'
  },
  {
    tab: 'ticket-top-section',
    label: 'Ticket Top Section',
    subSection: 'ticket-top-section'
  }
];

export const shiftSettings = [
  {
    tab: 'open-shift-template-configuration',
    label: 'Open Shift Configuration'
  },
  {
    tab: 'close-shift-template-configuration',
    label: 'Close Shift Configuration',
  }
]

export const shiftKeysTabs = [
  {
    tab: 'open-shift-keys',
    label: 'Open shift keys',
  },
  {
    tab: 'close-shift-keys',
    label: 'Close shift keys',
  }
];

export const permissions = [
  { name: 'companies' },
  { name: 'templates' },
  { name: 'translations' },
  { name: 'configurations'}
];

export const logoFileExtensions = ['png', 'jpg', 'jpeg', 'gif'];

export const templatesTabs = [
  {
    tab: 'ticket-template',
    label: 'Ticket Template'
  },
  {
    tab: 'open-shift-template',
    label: 'Open Shift Template'
  },
  {
    tab: 'close-shift-template',
    label: 'Close Shift Template'
  }
]

export const shiftTypes = {
  openShift: 'open',
  closeShift: 'close',
}

export const userEditTabs = [
  {
    tab: 'hierarchy-permission',
    label: 'Hierarchy'
  },
  {
    tab: 'wsbo-permission',
    label: 'Wsbo'
  },
  {
    tab: 'online-permission',
    label: 'Online'
  }
]

export const websiteTabs = [
  {
    tab: 'seo-rules',
    label: 'Seo rules',
    permission: `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.SEO_RULES.key}`
  },
  {
    tab: 'tooltips',
    label: 'Tooltips',
    permission: `${PERMISSION_NAMES.WEB_SITES.key}.${PERMISSION_NAMES.TOOLTIPS.key}`
  }
]
