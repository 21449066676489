<template>
  <div>
    <div class="c-form-wrapper--small">
      <div class="c-form">
        <div v-if="selectedConfigurationData.length" class="c-form__row">
          <generic-form-group 
            :label="ticketTemplateConfiguration.label"
            :disabled="!hasWritePermision || !selectedConfigurationData[findIndexByKey(ticketTemplateConfiguration)].enabled && type !== selectedType"  
          >
            <span v-if="!ticketTemplates.length">No templates found, in order to choose template, first create one.</span>
            <md-field class="c-select-field c-select">
              <label>{{ selectedTemplateId ? '' : ticketTemplateConfiguration.label }}</label>
              <md-select 
                v-model="selectedTemplateId" 
                :disabled="!ticketTemplates.length"
                @md-selected="onChangeSectionOrTamplate"
              >
                <md-option
                  v-for="ticketTemplate in ticketTemplates"
                  :key="ticketTemplate.id"
                  :value="ticketTemplate.id"
                  @md-selected="onChangeSectionOrTamplate"
                >
                  {{ ticketTemplate.name }}
                </md-option>
              </md-select>
            </md-field>
          </generic-form-group>
          <div v-if="type !== selectedType" class="c-form__checkbox">
            <md-checkbox v-model="selectedConfigurationData[findIndexByKey(ticketTemplateConfiguration)].enabled" :disabled="!hasWritePermision" class="c-form-checkbox__checkmark" /><span>Enable Configuration </span>
          </div>
        </div>

        <div v-if="selectedConfigurationData.length" class="c-form__row">
          <generic-form-group
            class="u-align-items-center"
            :label="ticketPrintSectionsConfiguration.label"
            :disabled="!hasWritePermision || (!selectedConfigurationData[findIndexByKey(ticketPrintSectionsConfiguration)].enabled && type !== selectedType) || !selectedTemplateId"
          >
            <span v-if="!selectedTemplateId">In order to disable keys, choose template first.</span>
            <md-field class="c-select-field">
              <label>{{ selectedConfigurationData[findIndexByKey(ticketPrintSectionsConfiguration)].value.length === 0 ? 'All sections are enabled.' : '' }}</label>
              <md-select v-model="selectedConfigurationData[findIndexByKey(ticketPrintSectionsConfiguration)].value" multiple @md-selected="onChangeSectionOrTamplate">
                <md-option
                  v-for="ticketKey in getAllTicketKeys"
                  :key="ticketKey.id"
                  :value="ticketKey.value"
                  @md-selected="onChangeSectionOrTamplate"
                >
                  {{ ticketKey.value }}
                </md-option>
              </md-select>
            </md-field>
          </generic-form-group>
          <div v-if="type !== selectedType" class="c-form__checkbox"> 
            <md-checkbox v-model="selectedConfigurationData[findIndexByKey(ticketPrintSectionsConfiguration)].enabled" :disabled="!hasWritePermision" class="c-form-checkbox__checkmark" /><span>Enable Configuration </span>
          </div>
        </div>
      </div>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <div class="u-mr-md">
        <generic-button variation="red" :disabled="!hasWritePermision" @click="updateSettings(true)">
          Clear All
        </generic-button>
      </div>
      <generic-button variation="red" :disabled="!hasWritePermision" @click="updateSettings">
        Update
      </generic-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import get from "lodash/get";
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import GenericFormGroup from "../../../shared/form-group/GenericFormGroup";
import { configurationsService } from "../../../services/configurations";
import { ticketTemplateService } from "../../../services/betshop/ticket-template";
import GenericButton from "../../../shared/buttons/GenericButton";
import { hasWritePermission } from '../../../helpers/permission';
import { PAGE_INFO } from '../../../const/pagination';

export default {
  name: "PrintTicketSettings",
  components: {
    GenericButton,
    GenericFormGroup,
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedType: "market",
      ticketTemplates: [],
      selectedTemplateId: null,
      clearAll: false,
      hasWritePermision: false,
      selectedConfigurationData: [],
      paginationData: {
        limitPerPage: 999,
        currentPage: PAGE_INFO.INITIAL_PAGE,
      },
    };
  },

  computed: {
    ...mapGetters("company", [
      "getApiConfigurationsData",
      "getApiMarketConfigurationsData",
      "getApiBetshopConfigurationsData",
    ]),

    ...mapGetters("ticket", ["getAllTicketKeys"]),

    getSelectedTemplateId() {
      const index = findIndex(this.selectedConfigurationData, { key: 'ticket_template'} );
      return this.selectedConfigurationData[index].value;
    },

    ticketTemplateConfiguration() {
      const index = findIndex(this.getApiConfigurationsData, { key: 'ticket_template' });

      return this.getApiConfigurationsData[index]
    },

    ticketPrintSectionsConfiguration() {
      const index = findIndex(this.getApiConfigurationsData, { key: 'ticket_print_sections' });

      return this.getApiConfigurationsData[index]
    }
  },

  async created() {
    this.setConfigurations();
    this.selectedTemplateId = this.getSelectedTemplateId;
    this.fetchTicketKeys();
    this.fetchTicketTemplates();
    this.checkPermissions();
    await this.getMarketLanguages({
      companyId: this.$route.params.companyId,
      marketId: this.$route.params.marketId,
    });
  },

  methods: {
    ...mapActions("response", [
      "handleSuccess",
      "handleError",
      "setGlobalError",
    ]),
    ...mapActions("company", [
      "updateMarketConfigurations",
      "updateBetshopConfigurations",
    ]),
    ...mapActions("company", [
        "getMarketLanguages",
      ]),
    ...mapActions("ticket", ["fetchAllTicketKeys"]),
    ...mapGetters('account', ['user', 'userHierarchyWriteableSections']),

    async updateSettings(clearAll) {
      if (clearAll) {
        this.selectedTemplateId = null;
        this.clearAll = true;
      }

      this.type === this.selectedType
        ? await this.updateMarket()
        : await this.updateBetshop();
      this.handleSuccess({ message: "Successfuly updated configurations!" });
      this.clearAll = false;
      this.$emit('canChangeTab', false);
    },

    async updateMarket() {
      const { data } = await configurationsService.updateMultipleMarketConfigurations(
        get(this.$route, "params.marketId"),
        this.selectedConfigurationData.map((config) => ({
          ...config,
          value: this.prepareValues(config)
        }))
      );
      this.updateMarketConfigurations(data);
    },

    async updateBetshop() {
      const { data } = await configurationsService.updateMultipleBetshopConfigurations(
        get(this.$route, "params.betshopId"),
        this.selectedConfigurationData.map((config) => ({
          ...config,
          value: this.prepareValues(config)
        }))
      );
      this.updateBetshopConfigurations(data);
    },

    prepareValues(config) {
      if (config.key === 'ticket_template' ) {
        return JSON.stringify(this.clearAll ? '' : this.selectedTemplateId);
      }

      if (config.key === 'ticket_print_sections' && this.clearAll) {
        return JSON.stringify([]);
      }

      return JSON.stringify(get(config, 'value'))
    },

    async fetchTicketTemplates() {
      try {
        const { data } = await ticketTemplateService.getAllTicketTemplates();
        this.ticketTemplates = data;
      } catch (error) {
        this.setGlobalError(get(error, "data.message"));
      }
    },

    findIndexByKey(configuration) {
      return this.selectedConfigurationData.findIndex(
        (config) => config.configurationId === configuration.id
      );
    },

    onChangeSectionOrTamplate() {
      this.$emit('canChangeTab', true);
    },

    checkPermissions() {
      this.hasWritePermision = hasWritePermission(
        this.user(),
        this.userHierarchyWriteableSections(),
        `print-settings-${this.type}`
      );
    },

    setConfigurations() {
      this.selectedConfigurationData = 
        this.type === this.selectedType ? 
          cloneDeep(this.getApiMarketConfigurationsData) : 
          cloneDeep(this.getApiBetshopConfigurationsData);
    },

    async fetchTicketKeys() {
      await this.fetchAllTicketKeys({
        currentPage: this.paginationData.currentPage,
        limit: this.paginationData.limitPerPage,
      });
    },
  },
};
</script>
