<template>
  <div class="wrapper">
    <md-checkbox v-model="rawHtmlDisplay" :disabled="disabled">
      Switch HTML Editor
    </md-checkbox>

    <wysiwyg
      v-if="!rawHtmlDisplay && !disabled"
      class="editor"
      :html="value"
      :disabled="disabled"
      @change="update"
    />
    <textarea
      v-if="rawHtmlDisplay || disabled"
      class="editor"
      :value="value"
      :disabled="disabled"
      @input="update($event.target.value)"
    />
  </div>
</template>
<script>
export default {
  name: 'WysiwygComponent',
  props: {
    value: {
      required: false,
      default: ''
    },
    disabled: {
      required: false,
      default: false,
      type: Boolean,
    }
  },
  data() {
    return {
      rawHtmlDisplay: false,
    }
  },
  methods: {
    update(newValue) {
      this.$emit('input', newValue);
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.editor {
  min-height: 300px;
  width: 100%;
  resize: none;
}

textarea {
  outline: red;
}

.md-checkbox {
  display: flex;
  align-items: center;
}

</style>

