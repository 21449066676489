<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link c-breadcrumbs__home"
            @click="goToHome"
          >
            <md-icon>home</md-icon>
            <span>Home</span>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Languages</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper">
        <base-table-component
          :prop-data="languages"
        >
          <template v-slot:action>
            <div class="header-table-section">
              Languages
            </div>
            <div class="add-button">
              <div>
                List of all languages
              </div>
              <div
                v-if="canWrite(`${PERMISSION_NAMES.TRANSLATIONS.key}.${PERMISSION_NAMES.TRANSLATIONS.key}`)"
                class="add-section"
              >
                <img src="../../../../assets/add-button.svg" class="add-button__new" alt="" @click="openModal()"><span>Add New Language</span>
              </div>
            </div>
          </template>
          <template v-slot:default="slotProps">
            <span class="c-table__btn-wrap">
              <generic-button
                icon
                transparent
                text-inherit
                :disabled="!canWrite(`${PERMISSION_NAMES.TRANSLATIONS.key}.${PERMISSION_NAMES.TRANSLATIONS.key}`)"
                @click="openModal(true, slotProps.selected.language)"
              >
                <md-tooltip md-direction="top">
                  Edit
                </md-tooltip>
                <img src="../../../../assets/edit-button.svg" alt="">
              </generic-button>
            </span>
            <span class="c-table__btn-wrap">
              <generic-button
                icon
                transparent
                text-inherit
                :disabled="!canWrite(`${PERMISSION_NAMES.TRANSLATIONS.key}.${PERMISSION_NAMES.TRANSLATIONS.key}`)"
              >
                <md-tooltip
                  md-direction="top"
                >
                  Import CSV
                </md-tooltip>
                <label
                  class="u-cursor-pointer"
                  for="file"
                  @click="handleClickOnUploadBtn(slotProps.selected.language.id)"
                >
                  <md-icon>arrow_upward</md-icon>
                </label>
                <input
                  id="file"
                  ref="file"
                  class="md-file-input"
                  type="file"
                  @change="handleOnFileChange($event)"
                >
              </generic-button>
            </span>
            <span class="c-table__btn-wrap">
              <generic-button
                icon
                transparent
                text-inherit
                :disabled="!canWrite(`${PERMISSION_NAMES.TRANSLATIONS.key}.${PERMISSION_NAMES.TRANSLATIONS.key}`)"
                @click="openModalForDelete(slotProps.selected.language)"
              >
                <md-tooltip md-direction="top">
                  Delete
                </md-tooltip>
                <img src="../../../../assets/delete-button.svg" alt="">
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>
      <paginate
        v-if="shouldShowPagination"
        :page-count="paginationData.pageCount"
        :force-page="currentPage"
        :prev-text="'<'"
        :next-text="'>'"
        :page-range="limitPerPage"
        :click-handler="fetchCurrentPage"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import LanguageModal from './components/create-update/index';
import BaseTableComponent from "../../../../shared/table/BaseTableComponent";
import { languageService } from "../../../../services/language/index";
import { paginationService } from "../../../../services/pagination/index";
import DeleteModalComponent from '../../../../shared/delete-modal/DeleteModalComponent'
import { mapActions } from "vuex";
import GenericButton from "../../../../shared/buttons/GenericButton";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import {PERMISSION_NAMES} from "@/const/online-permissions";

export default {
  components: {
    BaseTableComponent,
    GenericButton
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      permissionName: `${PERMISSION_NAMES.TRANSLATIONS.key}.${PERMISSION_NAMES.TRANSLATIONS.key}`,

      currentPage: 1,
      limitPerPage: 10,
      paginationData: {},
      languages: [],
      openConfirmModal: false,
      file: '',
      currentLanguageForImportCSV: null
    }
  },
  computed: {
    PERMISSION_NAMES() {
      return PERMISSION_NAMES
    },
    shouldShowPagination() {
      return this.paginationData.totalItems > this.limitPerPage
    },
  },
  created() {
    this.fetchCurrentPage();
  },
  methods: {
    ...mapActions("response", ["handleSuccess"]),

    fetchLanguages(page, limit) {
      languageService.getOnLineAllWithPagination(page, limit).then(response => {
        if (response.data.items.length === 0 && this.currentPage > 1) {
          this.fetchCurrentPage(this.currentPage - 1)

          return;
        }
        this.paginationData = paginationService.getPaginationData(response.data);
        this.languages = languageService.formatDataForTable(response.data.items);
      });
    },

    fetchCurrentPage(page = 1, limit = this.limitPerPage) {
      this.currentPage = page;
      this.fetchLanguages(page, limit);
    },

    goToHome() {
      return this.$router.push({ name: 'home' });
    },

    openModalForDelete(language) {
      this.$modal.show(
        DeleteModalComponent,
        {
          propData: language.id,
          deleteFunction: this.deleteLanguage,
          getDataFunction: this.fetchCurrentPage,
          deleteMsg: language.name,
          pageData: {currentPage: this.currentPage, limit: this.limitPerPage }
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    async deleteLanguage(id) {
      return await languageService.onlineDelete(id);
    },

    handleClickOnUploadBtn(id) {
      this.currentLanguageForImportCSV = id;
    },

    openModal(isUpdate = false, languageData = { name: null, code: null }) {
      this.$modal.show(
        LanguageModal,
        { fetchCurrentPage: this.fetchCurrentPage, currentPage: this.currentPage, languageData, isUpdate },
        { height: "auto", scrollable: true }
      );
    },

    async handleOnFileChange(event) {
      let formData = new FormData();
      let file = event.target.files[0];
      formData.set('file', file);
      await languageService.uploadOnlineCSVFile(formData, this.currentLanguageForImportCSV);
       this.handleSuccess({ message: `Translations imported!` });
    }
  }
};
</script>

<style lang="scss" scoped>
.u-button-add {
  position: fixed;
  bottom: 1vh;
  right: 3vw;
}

.md-file-input {
  display: none;
}

.custom-file-upload {
  margin: 0;
  cursor: pointer;
  padding: 10px;
}

.md-button-file-upload {
  outline: none;
}
</style>
