<template>
  <div>
    <div class="c-form-wrapper u-overflow-auto">
      <div class="c-form__permissions-actions">
        <div class="c-form__permissions-actions--inner">
          <span>Read</span>
          <span>Write</span>
        </div>
      </div>

      <div
        v-for="(permissionSection, index) in currentPermissions"
        :key="index"
        class="c-table c-table--permissions"
      >
        <div class="c-table__head">
          <div class="c-table__cell c-table__cell--permissions u-text-align-left">
            {{ PERMISSION_NAMES[permissionSection.name].label }}
          </div>
        </div>

        <div v-if="permissionSection.name === PERMISSION_NAMES.WEB_SITES.key" class="c-table__body c-table__section">
          <div class="c-table__row">
            <div class="c-table__custom-cell u-justify-start">
              Allowed websites
            </div>
          </div>
          <div class="c-table__custom-row">
            <div class="c-table__custom-cell">
              <span> name </span>
            </div>
            <div class="c-table__custom-cell">
              <span> url </span>
            </div>
            <div class="c-table__custom-cell">
              <span> betshop ID </span>
            </div>
            <div class="c-table__permissions-actions" />
          </div>
          <div
            v-for="website in websites"
            :key="`website-${website.id}`"
            class="c-table__custom-row"
          >
            <div class="c-table__custom-cell">
              <span>{{ website.name }}</span>
            </div>
            <div class="c-table__custom-cell">
              <span>{{ website.url }}</span>
            </div>
            <div class="c-table__custom-cell">
              <span>{{ website.betshop.id }}</span>
            </div>
            <div class="c-table__permissions-actions">
              <div class="u-flex-justify-around">
                <md-checkbox
                  v-model="currentOnlineWebsitesPermissions[website.id].read"
                  class="u-mv-0"
                  @change="handlePermissionChange($event, currentOnlineWebsitesPermissions[website.id], 'read')"
                />
                <md-checkbox
                  v-model="currentOnlineWebsitesPermissions[website.id].write"
                  class="u-mv-0"
                  @change="handlePermissionChange($event, currentOnlineWebsitesPermissions[website.id], 'write')"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-for="(permission, i) in permissionSection.items"
          :key="`${permissionSection.name}-${permission.name}-${i}`"
          class="c-table__body"
        >
          <div class="c-table__row">
            <div class="c-table__cell c-table__cell--permissions u-ph-xl">
              {{ PERMISSION_NAMES[permission.name].label }}
            </div>
            <div class="c-table__permissions-actions">
              <div class="u-flex-justify-around">
                <md-checkbox
                  v-model="permission.read"
                  class="u-mv-0"
                  @change="handlePermissionChange($event, permission, 'read')"
                />
                <md-checkbox
                  v-model="permission.write"
                  class="u-mv-0"
                  @change="handlePermissionChange($event, permission, 'write')"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <div class="u-mr-md">
        <generic-button
          variation="red"
          @click="goToUsers"
        >
          Cancel
        </generic-button>
      </div>
      <generic-button variation="red" @click="updateUser">
        Save
      </generic-button>
    </div>
  </div>
</template>

<script>
import GenericButton from "../../../shared/buttons/GenericButton";
import {mapActions, mapGetters} from "vuex";
import {responseService} from "@/services/response";
import {userService} from "@/services/user";
import {websiteService} from "@/services/websites";
import {onlinePermissions, PERMISSION_NAMES} from "@/const/online-permissions";
import {jsonParse} from "@/helpers/transformers";

export default {
  name: "OnlinePermission",
  components: {
    GenericButton,
  },
  data() {
    return {
      currentPermissions: [],
      currentOnlineWebsitesPermissions: {},
      currentUser: {},
      websites: [],
    };
  },
  computed: {
    ...mapGetters("account", ["user", "userOnlinePermissions"]),
    PERMISSION_NAMES() {
      return PERMISSION_NAMES
    },
  },
  async created() {
    await this.setUpCurrentWebsitePermissions();
    await this.setUpCurrentUserPermissions();
  },
  methods: {
    ...mapActions("response", ["handleSuccess", "handleError"]),

    goToUsers() {
      this.$router.push({name: "users"});
    },

    formatPermissions() {
      let currentUserPermissions = jsonParse(this.currentUser.onlinePermissions) || {};

      this.formatOnlinePermissions(currentUserPermissions);
      this.formatOnlineWebsitePermissions(currentUserPermissions);
    },

    async updateUser() {
      try {
        const data = {...this.currentUser};
        data.adminId = this.user.id;
        data.onlinePermissions = JSON.stringify(this.formatOnlinePermissionsForUpdate());

        await userService.update(data);
        this.handleSuccess({
          message: "Users permissions updated successfully.",
        });
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
      this.$emit('canChangeTab', false);
    },

    formatOnlinePermissionsForUpdate() {
      const data = {
        permissions: {},
        onlineWebsitePermissions: {},
      };

      this.currentPermissions.forEach(permissionGroup => {
        permissionGroup.items.forEach(permission => {
          data.permissions[`${permissionGroup.name}.${permission.name}`] = permission.write
            ? 'write'
            : permission.read
              ? 'read'
              : ''
        })
      })

      for (const websiteId in this.currentOnlineWebsitesPermissions) {
        data.onlineWebsitePermissions[websiteId] = this.currentOnlineWebsitesPermissions[websiteId].write
          ? 'write'
          : this.currentOnlineWebsitesPermissions[websiteId].read
            ? 'read'
            : ''
      }

      return data;
    },

    handlePermissionChange(value, field, permission) {
      this.$emit('canChangeTab', true);

      if (permission === 'read' && !value) {
        field.write = false;
      }

      if (permission === 'write' && field) {
        field.read = true;
      }
    },

    async setUpCurrentUserPermissions() {
      const {data} = await userService.getSingleUser(this.$route.params.id);
      this.currentUser = data;

      this.formatPermissions();
    },

    async setUpCurrentWebsitePermissions() {
      const {data} = await websiteService.getWebsites();
      this.websites = data;
    },

    formatOnlinePermissions(currentUserPermissions) {
      const groups = [];

      for (const permission in onlinePermissions) {
        const [permissionGroup, permissionStage] = permission.split('.');
        let group = groups.find(g => g.name === permissionGroup)

        if (!group) {
          group = {name: permissionGroup, items: []};
          groups.push(group);
        }

        const read = currentUserPermissions?.permissions?.[permission] === 'read' || currentUserPermissions?.permissions?.[permission] === 'write';
        const write = currentUserPermissions?.permissions?.[permission] === 'write';

        group.items.push({name: permissionStage, read, write});
      }

      this.currentPermissions = groups
    },

    formatOnlineWebsitePermissions(currentUserPermissions) {
      const onlineWebsitePermissions = {}

      this.websites.forEach(website => {
        onlineWebsitePermissions[website.id] = {
          read: currentUserPermissions?.onlineWebsitePermissions?.[website.id] === 'read' || currentUserPermissions?.onlineWebsitePermissions?.[website.id] === 'write',
          write: currentUserPermissions?.onlineWebsitePermissions?.[website.id] === 'write',
        };
      })

      this.currentOnlineWebsitesPermissions = onlineWebsitePermissions;
    }
  },
};
</script>
<style lang="scss" scoped>

.c-table__custom-cell, .c-table__custom-row {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  & > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 5px;
  }

  span {
    text-align: left;
    justify-self: flex-start;
  }

  .c-table__permissions-actions {
    max-width: 15rem;
    margin-left: auto;
  }
}

.c-table__custom-cell {
  padding: 11px 2rem;
}

.c-table__custom-row {
  border-bottom: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  align-items: center;
}
</style>

