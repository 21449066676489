import { authService } from '../../services/auth'

const token = localStorage.getItem('token')

function getInitialState() {
  return {
    status: {},
    user: {},
    userAllowedRoutes: [],
    userAllowedSidebarItems: [],
    userAllowedOnLineSidebarItems: [],
    userReadableSections: [],
    userWriteableSections: [],
    userHierarchyAllowedTabs: [],
    userHierarchyReadableSections: [],
    userHierarchyWriteableSections: [],
    userOnlinePermissions: {},
    loginError: null,
    authToken: token || null,
    needsActivation: false,
    activationError: null,
  }
}

const state = getInitialState()

const actions = {
  async handleLogin({ commit }, credential) {
    try {
      commit('LOGIN_ERROR', null);
      const response = await authService.login(credential);
      if (!response.data) {
        commit('NEEDS_ACTIVATION', true);
      }

      return response;
    } catch(error) {
      console.info('Failed login', error)
      commit('LOGIN_ERROR', error.data.message)
      throw error
    }
  },

  async handleActivation({ commit }, data) {
    try {
      localStorage.setItem('activation-token', data.activationToken);

      commit('ACTIVATION_ERROR', null);
      return await authService.activate(data);
    } catch(response) {
      console.info(response)
      commit('LOGIN_ERROR', response.data.message)
    }
  }
}

const getters = {
  user: state => state.user,
  isLoggedIn: state => state.status.loggedIn,
  activationError: state => state.activationError,
  needsActivation: state => state.needsActivation,
  userAllowedRoutes: state => state.userAllowedRoutes,
  userAllowedSidebarItems: state => state.userAllowedSidebarItems,
  userAllowedOnLineSidebarItems: state => state.userAllowedOnLineSidebarItems,
  userReadableSections: state => state.userReadableSections,
  userWriteableSections: state => state.userWriteableSections,
  userAlowedSidebarItems: state => state.userAlowedSidebarItems,
  userHierarchyAllowedTabs: state => state.userHierarchyAllowedTabs,
  userHierarchyReadableSections: state => state.userHierarchyReadableSections,
  userHierarchyWriteableSections: state => state.userHierarchyWriteableSections,
  userOnlinePermissions: state => state.userOnlinePermissions,
}

const mutations = {
  LOGIN_SUCCESS(
    state,
    {
      access_token,
      userAllowedRoutes,
      userAllowedSidebarItems,
      userAllowedOnLineSidebarItems,
      userReadableSections,
      userWriteableSections,
      userHierarchyAllowedTabs,
      userHierarchyReadableSections,
      userHierarchyWriteableSections,
      userOnlinePermissions,
      user,
    }
  ) {
    state.status = { loggedIn: true };
    state.authToken = access_token;
    state.user = user;
    state.userAllowedRoutes = userAllowedRoutes;
    state.userAllowedSidebarItems = userAllowedSidebarItems;
    state.userAllowedOnLineSidebarItems = userAllowedOnLineSidebarItems;
    state.userReadableSections = userReadableSections;
    state.userWriteableSections = userWriteableSections;
    state.userHierarchyAllowedTabs = userHierarchyAllowedTabs;
    state.userHierarchyReadableSections = userHierarchyReadableSections;
    state.userHierarchyWriteableSections = userHierarchyWriteableSections;
    state.userOnlinePermissions = userOnlinePermissions;
  },
  NEEDS_ACTIVATION(state, data) {
    state.needsActivation = data;
  },
  LOGIN_ERROR(state, data) {
    state.loginError = data;
  },
  ACTIVATION_ERROR(state, data) {
    state.activationError = data;
  },
  SET_ALOWED_ROUTES(state, data) {
    state.userAllowedRoutes = data;
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
