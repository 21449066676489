<template>
  <div>
    <div class="c-form-wrapper u-overflow-auto">
      <div class="c-form__permissions-actions">
        <div class="c-form__permissions-actions--inner">
          <span>Read</span>
          <span>Write</span>
        </div>
      </div>
      <div
        v-for="(permissionSection, index) in currentUserPermissions"
        :key="index"
        class="c-table c-table--permissions"
      >
        <div class="c-table__head">
          <div class="c-table__cell c-table__cell--permissions u-text-align-left">
            {{ formatLabel(index) }}
          </div>
        </div>

        <div
          v-for="permission in permissionSection.permissions"
          :key="permission.pageTab"
          class="c-table__body"
        >
          <div v-if="permission.pageTab" class="c-table__row">
            <div class="c-table__cell c-table__cell--permissions u-ph-xl">
              {{ formatLabel(permission.pageTab) }}
            </div>
            <div class="c-table__permissions-actions">
              <div class="u-flex-justify-around">
                <md-checkbox v-model="permission.read" class="u-mv-0" @change="onChangeReadPermission()" />
                <md-checkbox
                  v-model="permission.write"
                  class="u-mv-0"
                  @change="onChangeWritePermission($event, permission, index)"
                />
              </div>
            </div>
          </div>
          <div v-else class="c-table__row">
            <div class="c-table__cell c-table__cell--permissions u-ph-xl">
              {{ formatLabel(permission.page) }}
            </div>
            <div class="c-table__permissions-actions">
              <div class="u-flex-justify-around">
                <md-checkbox v-model="permission.read" class="u-mv-0" @change="onChangeReadPermission()" />
                <md-checkbox
                  v-model="permission.write"
                  class="u-mv-0"
                  @change="onChangeWritePermission($event, permission, index)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <div class="u-mr-md">
        <generic-button
          variation="red"
          @click="goToUsers"
        >
          Cancel
        </generic-button>
      </div>
      <generic-button variation="red" @click="updateUser">
        Save
      </generic-button>
    </div>
  </div>
</template>

<script>
import GenericButton from "../../../shared/buttons/GenericButton";
import { wsboPermission } from "../../../const/permissions";
import { mapActions, mapGetters } from "vuex";
import capitalize from "lodash/capitalize";
import { responseService } from "../../../services/response";
import { userService } from "../../../services/user";
import each from "lodash/each";
import replace from "lodash/replace";
import get from "lodash/get";
import set from "lodash/set";
import isEmpty from "lodash/isEmpty";
import find from "lodash/find";

export default {
  name: "WsboPermission",
  components: {
    GenericButton,
  },
  data() {
    return {
      currentUserPermissions: {
        'companies': {
          permissions: [],
        },
        'templates': {
          permissions: [],
        },
        'translations': {
          permissions: [],
        },
        'websites': {
          permissions: [],
        },
        'configurations': {
          permissions: [],
        },
        'manage-drivers': {
          permissions: [],
        },
      },
      currentUser: {}
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),
  },
  async created() {
    await this.setUpCurrentUserPermissions();
  },
  destroyed() {
    this.resetDefaultValues();
  },
  methods: {
    ...mapActions("response", ["handleSuccess", "handleError"]),
    onChangeReadPermission() {
      this.$emit('canChangeTab', true);
    },
    goToUsers() {
      this.$router.push({ name: "users" });
    },
    formatLabel(label) {
      return capitalize(replace(label, new RegExp("-", "g"), " "));
    },
    async updateUser() {
      try {
        let permissionsToUpdate = {};

        each(this.currentUserPermissions, (section, index) => {
          if (!isEmpty(section.permissions.find((p) => p.read || p.write))) {
            set(
              permissionsToUpdate,
              `${index}.permissions`,
              section.permissions.filter((p) => p.read || p.write)
            );
          }
        });

        this.currentUser.wsboPermissions = JSON.stringify(permissionsToUpdate);
        this.currentUser.adminId = this.user.id;
        await userService.update(this.currentUser);
        this.handleSuccess({
          message: "Users permissions updated successfully.",
        });
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
      this.$emit('canChangeTab', false);
    },

    onChangeWritePermission(value, field, section) {
        this.$emit('canChangeTab', true);
        if (!value) {
          return;
        }

        each(this.currentUserPermissions[section].permissions, permission => {
          if (permission.key === field.key) {
            permission.write = value;
            permission.read = value;

            return false;
          }
        });
    },

    async setUpCurrentUserPermissions() {
      const { data } = await userService.getSingleUser(this.$route.params.id);
      this.currentUser = data;

      if (!this.currentUser.wsboPermissions) {
        this.currentUserPermissions = wsboPermission;

        return;
      }

      const parsedUserPermissions = JSON.parse(
        this.currentUser.wsboPermissions
      );

      each(wsboPermission, (section, index) => {
        let userPermissions = get(parsedUserPermissions[index], "permissions");

        each(get(section, "permissions"), (item) => {
          let currentItem = find(userPermissions, { key: item.key });

          if (!currentItem) {
            this.currentUserPermissions[index].permissions.push(item);

            return;
          }

          this.currentUserPermissions[index].permissions.push(currentItem);
        });
      });
      this.initialUserPermissions = this.currentUserPermissions;
    },

    resetDefaultValues() {
      each(wsboPermission, (sect) => {
        each(sect.permissions, (perm) => {
          perm.read = false;
          perm.write = false;
        });
      });
      this.$emit('canChangeTab', false);
    },
  },
};
</script>
