import { apiService } from '../api'

const ENDPOINTS = {
  GET: '/websites',
  DELETE: '/websites',
  CREATE: '/websites',
  UPDATE: '/websites',
}

class WebsiteService {

  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data);
  }

  async update(data) {
    return await apiService.patch(`${ENDPOINTS.UPDATE}/${data.id}`, data);
  }

  async getWebsites() {
    return await apiService.get(ENDPOINTS.GET);
  }

  async getSingleWebsite(id) {
    return await apiService.get(`${ENDPOINTS.DELETE}/${id}`);
  }

  async deleteWebsite(id) {
    return await apiService.delete(`${ENDPOINTS.DELETE}/${id}`);
  }

  prepareWebsitesDataFromResponse(items) {
    const websites = [];

    items.forEach(website => {
      websites.push({'rowData': {
        id: website.id,
        name: website.name,
        url: website.url,
        betshopId: website.betshop.id,
        betshopName: website.betshop.name
        }
      });
    });

    return websites;
  }
}

export const websiteService = new WebsiteService()
