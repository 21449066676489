<template>
  <div class="l-manage-drivers u-mt-md c-table-wrapper">
    <base-table-component
      class="u-wordbreak-all"
      :prop-data="printers"
    >
      <template v-slot:action>
        <div class="header-table-section">
          Applications
        </div>
        <div v-if="userCanEdit" class="add-button">
          <div>
            App Manger
          </div>
          <div class="add-section">
            <img src="../../assets/add-button.svg" class="add-button__new" alt="" @click="createOrUpdatePrinter()">
          </div>
        </div>
      </template>
      <template v-slot:default="slotProps">
        <span class="c-table__btn-wrap">
          <generic-button
            v-if="userCanEdit"
            icon
            transparent
            text-inherit
            @click="createOrUpdatePrinter(slotProps.selected)"
          >
            <md-tooltip
              md-direction="top"
            >
              Edit
            </md-tooltip>
            <img src="../../assets/edit-button.svg" alt="">
          </generic-button>
        </span>
        <span class="c-table__btn-wrap">
          <generic-button
            v-if="userCanEdit"
            icon
            transparent
            text-inherit
            @click="openDeleteModal(slotProps.selected)"
          >
            <md-tooltip
              md-direction="top"
            >
              Delete
            </md-tooltip>
            <img src="../../assets/delete-button.svg" alt="">
          </generic-button>
        </span>
      </template>
    </base-table-component>
  </div>
</template>

<script>
import GenericButton from "../../shared/buttons/GenericButton";
import CreateUpdateModal from './components/create-update/index';
import { appManagerService } from "./../../services/app-manager";
import BaseTableComponent from '../../shared/table/BaseTableComponent';
import DeleteModalComponent from '../../shared/delete-modal/DeleteModalComponent';
import { mapGetters } from 'vuex';

export default {
  name: "AppManager",

  components: {
    GenericButton,
    BaseTableComponent
  },

  data() {
    return {
      printers: [],
      currentTab: 'versions-configurations',
    }
  },

  computed: {
    ...mapGetters('account', ['userWriteableSections']),

    userCanEdit() {
      return !!this.userWriteableSections.includes('drivers');
    }
  },

  created() {
    this.fetchPrinters()
  },

  methods: {
    fetchPrinters() {
      appManagerService.getAll().then(response => {
        this.printers = appManagerService.prepareDataFromResponse(response.data);
      });
    },

    createOrUpdatePrinter(selected) {
      this.$modal.show(
        CreateUpdateModal,
        {
          data: appManagerService.formatData(selected),
          fetchPrinters: this.fetchPrinters
        },
        { height: "auto", scrollable: true }
      );
    },

    openDeleteModal(selected) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `"${selected.rowData.name}`,
          propData: selected.id,
          deleteFunction: this.deleteSelected,
          getDataFunction: this.fetchPrinters,
          pageData: {currentPage: this.currentPage, limit: this.limitPerPage }
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    deleteSelected(id) {
      return appManagerService.delete(id);
    },
  },
};

</script>
