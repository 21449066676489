export const defaultWritableSections = [
  "companies",
  "ticket-template",
  "open-shift-template",
  "close-shift-template",
  "all-translations",
  "sections",
  "languages",
  "pages",
  "ticket-keys",
  "open-shift-keys",
  "close-shift-keys",
  "drivers",
  "versions",
  "hq-settings",
  'websites',
  'websites-edit',
  'seo-rules',
  'tooltips',
  'custom-pages',
  'casino-categories',
  'configurations-setup'
];
