<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link"
            @click="goToHome"
          > 
            <md-icon>home</md-icon>
            <span>Home</span>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link c-breadcrumbs__home">Manage Versions</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <md-tabs class="c-tabs c-tabs--large">
        <md-tab
          v-for="tab in manageAppTabs"
          :id="tab.tab"
          :key="tab.tab"
          :md-label="tab.label"
          @click="changeActiveTab(tab.tab)"
        />
      </md-tabs>
      <os-page :type="currentTab" />
    </div>
  </div>
</template>

<script>
import OsPage from './components/os-page';

export default {
  components: {
    OsPage,
  },
  data() {
    return {
      currentTab: 'linux',
      manageAppTabs: [
        {
          tab: 'linux',
          label: 'Linux'
        },
        {
          tab: 'windows',
          label: 'Windows'
        },
        {
          tab: 'mac',
          label: 'Mac'
        }
      ]
    };
  },

  methods: {
    changeActiveTab(tab) {
      this.currentTab = tab;
    },

    goToHome() {
      return this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style></style>
