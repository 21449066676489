<template>
  <div class="l-main">
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper c-table--online-configurations">
        <base-table-component :prop-data="paginatedData">
          <template v-slot:action>
            <div class="header-table-section">
              Configuration settings
            </div>
            <div class="add-button">
              <md-field
                md-inline
                class="u-width-m u-mb-xs"
              >
                <label>Search</label>
                <md-input
                  v-model="term"
                />
              </md-field>
              <div class="add-section">
                <img src="../../../../../assets/add-button.svg" class="add-button__new" alt="" @click="createOrUpdateOnlineConfiguration()"><span>Add New Configration Type</span>
              </div>
            </div>
          </template>
          <template v-slot:default="slotProps">
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="userCanEdit"
                icon
                transparent
                text-inherit
                @click="createOrUpdateOnlineConfiguration(slotProps.selected.onlineConfiguration)"
              >
                <md-tooltip md-direction="top"> Edit </md-tooltip>
                <img src="../../../../../assets/edit-button.svg" alt="">
              </generic-button>
            </span>

            <span class="c-table__btn-wrap">
              <generic-button
                v-if="userCanEdit"
                icon
                transparent
                text-inherit
                @click="openDeleteModal(slotProps.selected.onlineConfiguration)"
              >
                <md-tooltip md-direction="top"> Delete </md-tooltip>
                <img src="../../../../../assets/delete-button.svg" alt="">
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import BaseTableComponent from '../../../../../shared/table/BaseTableComponent'
import DeleteModalComponent from '../../../../../shared/delete-modal/DeleteModalComponent'
import CreateOnlineConfigurationModal from './create'
import get from 'lodash/get'
import GenericButton from '../../../../../shared/buttons/GenericButton'

export default {
  name: "OnlineConfigurationsList",

  components: {
    BaseTableComponent,
    GenericButton,
  },

  data() {
    return {
      currentPage: 1,
      limitPerPage: 20,
      term: '',
    };
  },

  computed: {
    ...mapGetters('account', ['userWriteableSections', 'userReadableSections', 'userAllowedRoutes', 'user']),
    ...mapGetters('onlineConfiguration', ['configurations', 'sections', 'subsections', 'types']),

    userCanEdit() {
      return this.userWriteableSections.includes('configurations-setup')
    },

    tableData() {
      return this.configurations.map(onlineConfiguration => ({
        onlineConfiguration: onlineConfiguration,
        rowData: {
          key: onlineConfiguration.key,
          type: this.types.find(type => type.id === onlineConfiguration.typeId)?.name,
          section: this.sections.find(section => section.id === onlineConfiguration.sectionId)?.name,
          subsection: this.subsections.find(subsection => subsection.id === onlineConfiguration.subsectionId)?.name,
        }
      }));
    },

    paginatedData() {
      return this.tableData?.filter(config => config.onlineConfiguration.key?.includes(this.term))
    },

    shouldShowPagination() {
      return this.tableData.length > this.limitPerPage;
    },

  },

  async created() {
    await Promise.all([
      this.getOnlineConfigurationSections(),
      this.getOnlineConfigurationSubsections(),
      this.getOnlineConfigurationTypes(),
    ])

    await this.getOnlineConfiguration();
  },

  methods: {
    ...mapActions('onlineConfiguration', [
      'getOnlineConfiguration',
      'getOnlineConfigurationSections',
      'getOnlineConfigurationSubsections',
      'getOnlineConfigurationTypes',
      'deleteOnlineConfiguration',
    ]),

    goToHome() {
      this.$router.push({ name: 'home' });
    },

    userCanSee(tab) {
      return this.userWriteableSections.includes(tab)
          || this.userReadableSections.includes(tab)
    },

    openDeleteModal(selected) {
      this.$modal.show(
          DeleteModalComponent,
          {
            deleteMsg: selected.key,
            propData: selected.id,
            deleteFunction: this.deleteOnlineConfiguration,
            getDataFunction: this.getOnlineConfiguration,
            pageData: {}
          },
          { height: "auto", scrollable: false, width: "400px" }
      );
    },

    createOrUpdateOnlineConfiguration(onlineConfiguration = {}) {
      this.$modal.show(
          CreateOnlineConfigurationModal,
          {
            onlineConfiguration,
            onlineConfigurationId: get(onlineConfiguration, 'id'),
          },
          { height: "auto", scrollable: true }
      );
    },

  },
};
</script>

<style lang="scss" scoped>
</style>
