<template>
  <div class="l-main">
    <div class="l-main__header">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link c-breadcrumbs__home" @click="goToHome">
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item--separator">
          /
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link c-breadcrumbs__home">Ticket keys</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper">
        <base-table-component :prop-data="keysToShow">
          <template v-slot:action>
            <div v-if="userCanEdit" class="add-button">
              <img src="../../../assets/add-button.svg" class="add-button__new" alt="" @click="createOrUpdateShiftKey()">
            </div>
          </template>
          <template v-slot:default="slotProps">
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="userCanEdit"
                icon
                transparent
                text-inherit
                @click="createOrUpdateShiftKey(slotProps.selected.rowData)"
              >
                <md-tooltip md-direction="top">
                  Edit
                </md-tooltip>
                <img src="../../../assets/edit-button.svg" alt="">
              </generic-button>
            </span>
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="userCanEdit"
                icon
                transparent
                text-inherit
                @click="openDeleteSectionModal(slotProps.selected.rowData)"
              >
                <md-tooltip md-direction="top">
                  Delete
                </md-tooltip>
                <img src="../../../assets/delete-button.svg" alt="">
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>
      <paginate
        v-if="shouldShowPagination"
        :page-count="getTicketKeysPaginationData.pageCount"
        :page-range="paginationData.limitPerPage"
        :force-page="paginationData.currentPage"
        :prev-text="'<'"
        :next-text="'>'"
        :click-handler="getDataPerPage"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GenericButton from "../../../shared/buttons/GenericButton";
import BaseTableComponent from "../../../shared/table/BaseTableComponent.vue";
import CreateUpdateModal from "./components/create-update/index";
import DeleteModalComponent from "../../../shared/delete-modal/DeleteModalComponent.vue";
import { PAGE_INFO } from '../../../const/pagination';
import { ticketService } from '../../../services/ticket';

export default {
  name: "OpenShiftKeys",
  components: {
    GenericButton,
    BaseTableComponent,
  },

  data() {
    return {
      paginationData: {
        limitPerPage: PAGE_INFO.LIMIT_PER_PAGE,
        currentPage: PAGE_INFO.INITIAL_PAGE,
      },
    };
  },
  
  computed: {
    ...mapGetters("ticket", ["getAllTicketKeys", "getTicketKeysPaginationData"]),
    ...mapGetters('account', ['userWriteableSections']),
    keysToShow: function () {
      return this.getAllTicketKeys.map(({ id, value }) => ({
        rowData: {
          id,
          name: value || `Ticket template ${id}`,
        },
      }));
    },
    shouldShowPagination() {
      return this.getTicketKeysPaginationData.totalItems > this.paginationData.limitPerPage;
    },

    userCanEdit() {
      return !!this.userWriteableSections.includes('ticket-keys');
    }
  },

  async created() {
    await this.fetchTicketKeys();
  },

  methods: {
    ...mapActions("ticket", ["fetchAllTicketKeys"]),

    createOrUpdateShiftKey(data = {}) {
      this.$modal.show(
        CreateUpdateModal,
        {
          data,
          paginationData: this.paginationData
        },
        { height: "auto", scrollable: true }
      );
    },

    openDeleteSectionModal(selected) {
      if (this.getTicketKeysPaginationData.totalItems % 10 === 1) {
        this.paginationData = {
          limitPerPage: 10,
          currentPage: this.paginationData.currentPage - 1 || 0
        }
      }

      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `key "${selected.name}`,
          deleteMsgAlert: `It will be deleted on all other configurations.`,
          propData: selected.id,
          deleteFunction: this.deleteTicketKey,
          getDataFunction: this.fetchTicketKeys,
          pageData: this.paginationData
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    goToHome() {
      return this.$router.push({ name: 'home' });
    },

    deleteTicketKey(id) {
      return ticketService.deleteTicketKey(id);
    },

    async fetchTicketKeys() {
      await this.fetchAllTicketKeys({
        currentPage: this.paginationData.currentPage,
        limit: this.paginationData.limitPerPage,
      });
    },

    getDataPerPage(
      currentPage = 1,
      limit = PAGE_INFO.LIMIT_PER_PAGE,
    ) {
      this.paginationData.currentPage = currentPage;
      this.fetchTicketKeys(currentPage, limit);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>