<template>
  <div class="l-main">
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper c-table--online-websites">
        <base-table-component :prop-data="tableData">
          <template v-slot:action>
            <div class="header-table-section">
              Casino Categories
            </div>
            <div class="add-button">
              <div>
                List of available casino categories
              </div>
              <div class="add-section">
                <img src="../../../../../assets/add-button.svg" class="add-button__new" alt="" @click="createOrUpdateCasinoCategory()"><span>Add New Casino Category</span>
              </div>
            </div>
          </template>
          <template v-slot:default="slotProps">
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="userCanEdit"
                icon
                transparent
                text-inherit
                @click="createOrUpdateCasinoCategory(slotProps.selected.page)"
              >
                <md-tooltip md-direction="top"> Edit </md-tooltip>
                <img src="../../../../../assets/edit-button.svg" alt="">
              </generic-button>
            </span>

            <span class="c-table__btn-wrap">
              <generic-button
                v-if="userCanEdit"
                icon
                transparent
                text-inherit
                @click="openDeleteModal(slotProps.selected.page)"
              >
                <md-tooltip md-direction="top"> Delete </md-tooltip>
                <img src="../../../../../assets/delete-button.svg" alt="">
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import BaseTableComponent from '../../../../../shared/table/BaseTableComponent'
import DeleteModalComponent from '../../../../../shared/delete-modal/DeleteModalComponent'
import CreateCasinoCategoryModal from '../../../configurations/components/casino-categories/create'
import get from 'lodash/get'
import GenericButton from '../../../../../shared/buttons/GenericButton'
import { casinoCategoriesService } from '@/services/casino-category'
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";

export default {
  name: "OnlineCasinoCategoriesList",

  components: {
    BaseTableComponent,
    GenericButton,
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      onlyDevelopersAllowed: true,
    }
  },

  computed: {
    ...mapGetters('account', ['userWriteableSections', 'userAllowedRoutes', 'user']),
    ...mapGetters('casinoCategories', ['casinoCategories']),

    userCanEdit() {
      return this.userWriteableSections.includes('casino-categories')
    },

    tableData() {
      return casinoCategoriesService.prepareCasinoCategoriesDataFromResponse(this.casinoCategories);
    }
  },

  created() {
    this.getCasinoCategories();
  },

  methods: {
    ...mapActions('casinoCategories', [
      'getCasinoCategories',
      'createCasinoCategory',
      'updateCasinoCategory',
      'deleteCasinoCategory'
    ]),
    changeActiveTab(tab) {
      this.selectedTab = tab;
    },

    goToHome() {
      this.$router.push({ name: 'home' });
    },

    userCanSee(tab) {
      return this.userWriteableSections.includes(tab)
          || this.userReadableSections.includes(tab)
    },

    openDeleteModal(selected) {
      this.$modal.show(
          DeleteModalComponent,
          {
            deleteMsg: selected.name,
            propData: selected.id,
            deleteFunction: this.deleteCasinoCategory,
            pageData: {}
          },
          { height: "auto", scrollable: false, width: "400px" }
      );
    },

    createOrUpdateCasinoCategory(casinoCategory = {}) {
      this.$modal.show(
          CreateCasinoCategoryModal,
          {
            casinoCategory: {
              name: get(casinoCategory, 'name', ''),
            },
            casinoCategoryId: get(casinoCategory, 'id'),
          },
          { height: "auto", scrollable: true }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
