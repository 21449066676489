<template>
  <div>
    <md-app v-if="isHomePage">
      <md-app-toolbar class="md-primary home-header" md-elevation="0">
        <md-menu md-size="medium" md-align-trigger class="u-ml-auto">
          <generic-button icon transparent md-menu-trigger>
            <md-icon>settings</md-icon>
          </generic-button>

          <md-menu-content>
            <div class="u-ph-md u-pv-xs">
              <md-switch v-model="nightMode" @change="handleThemeToggle">
                Dark mode
              </md-switch>
            </div>
            <md-menu-item @click="changePassword">
              Change password
            </md-menu-item>
            <md-menu-item @click="logout">
              Logout
            </md-menu-item>
          </md-menu-content>
        </md-menu>
      </md-app-toolbar>

      <md-app-content class="l-home">
        <div class="l-home-inner">
          <h4 class="c-title">
            Welcome to Meridianbet Backoffice
          </h4>

          <div class="c-cards">
            <div class="c-card">
              <img
                src="../../assets/meridianbet-logo.jpeg"
                class="c-card__thumbnail"
              >
              <h3 class="c-card__name">
                Meridianbet V4
              </h3>
              <p class="c-card__description">
                Meridianbet v4 backoffice configuration
              </p>
              <generic-button class="btn-red" @click="goToOnLine()">
                visit ONLINE bo
              </generic-button>
            </div>

            <div class="c-card">
              <img src="../../assets/betty-logo-2.svg" class="c-card__thumbnail">
              <h3 class="c-card__name">
                Meridian betshop
              </h3>
              <p class="c-card__description">
                Betty backoffice configuration
              </p>
              <generic-button class="btn-red" @click="goToOffline()">
                visit betty bo
              </generic-button>
            </div>
          </div>
        </div>
      </md-app-content>
    </md-app>
    <router-view />
    <snack-bar />
    <div
      v-if="fullscreenLoader"
      class="l-loader-global"
    >
      <md-progress-spinner
        class="c-spinner"
        :md-diameter="40"
        md-mode="indeterminate"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { authService } from "../../services/auth";
import ChangePasswordModal from "../change-password";
import GenericButton from "../../shared/buttons/GenericButton";
import includes from "lodash/includes";
import {onlinePermissionsMixin} from "@/mixins/onlinePermissionsMixin";
import SnackBar from "@/shared/snackebar/SnackeBar.vue";

export default {
  components: {
    SnackBar,
    GenericButton,
  },

  mixins: [onlinePermissionsMixin],

  data() {
    return {
      translationSelected: false,
      configurationsSelected: false,
      manageAppSelected: false,
      isHomePage: false,
    };
  },

  computed: {
    ...mapGetters("account", [
      "user",
      "userAllowedSidebarItems",
      "userWriteableSections",
      "userAllowedRoutes",
    ]),
    ...mapGetters("theme", ["isNightMode"]),
    ...mapGetters("response", ["fullscreenLoader"]),

    nightMode: {
      get() {
        return this.isNightMode;
      },
      set(mode) {
        return mode;
      },
    },
    isRouteForCompanies() {
      return includes(
        ["companies", "markets", "betshops", "betshop"],
        this.$route.name
      );
    },
  },

  watch: {
    $route() {
      this.isHomePage = this.$route.name === "home";
    },
  },

  created() {
    this.isHomePage = this.$route.name === "home";
  },

  methods: {
    ...mapActions("response", ["setGlobalError"]),
    ...mapActions("account", ["handleLoginSuccess"]),
    ...mapActions("theme", ["handleThemeToggle"]),

    logout() {
      authService.logout(this.user.email);
    },

    goToOffline() {
      this.canUserGoToOffline() ?
          this.$router.push({ name: "companies" })
          : this.$router.push({ name: this.userAllowedRoutes[0] });
      },

    goToOnLine() {
      const firstAllowedRoutePathName = this.getFirstAllowedRoutePathName();

      if (!firstAllowedRoutePathName) {
        this.setGlobalError('You do not have permissions go to this page');
        return;
      }

      this.$router.push({name: firstAllowedRoutePathName});
    },

    canUserGoToOffline() {
      return this.user.role === 1 || this.userAllowedRoutes.includes("companies");
    },

    changePassword() {
      this.$modal.show(
        ChangePasswordModal,
        {},
        { height: "auto", scrollable: true }
      );
    },

    goToCompanies() {
      if (this.$route.name === 'companies') {
        return;
      }

      if (this.user.role === 1) {
        return this.$router.push({ name: "companies" });
      }

      if (this.$route.name === this.userAllowedRoutes[0]) {
        return;
      }

      this.userAllowedRoutes.includes("companies")
        ? this.$router.push({ name: "companies" })
        : this.$router.push({ name: this.userAllowedRoutes[0] });
    },
  },
};
</script>

<style lang="scss">
.l-home {
  height: calc(100vh - 64px);
  overflow: hidden;
  background-color: map-get($content-background, lightTheme) !important;
  padding: 0;

  @include dark-theme {
    background-color: map-get($content-background, darkTheme) !important;
  }

  @media (max-width: 960px) {
    height: calc(100vh - 48px);
  }
}

.l-home--main {
  background: url(https://img2.goodfon.com/wallpaper/nbig/d/d1/sport-football-wallpaper-stadium-fans-emotions.jpg);
  background-size: cover;
}

.l-home-inner {
  width: 700px;
  margin: 0 auto;
  padding: 100px 0;
}

.l-loader-global {
  position: fixed;
  background-color: rgba(0,0,0,.2);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  inset: 0;
}
</style>
