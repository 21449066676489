import Vue from 'vue'
import Router from 'vue-router'
import { authService } from '../services/auth'
import HomePage from '../pages/home'
import ActivatePage from '../pages/activate'
import LoginPage from '../pages/login'
import Companies from '../pages/companies'
import Markets from '../pages/markets'
import Betshops from '../pages/betshops'
import SingleBetshop from '../pages/single-betshop'
import AllUsers from '../pages/user'
import EditUSer from '../pages/user/update-user'
import AllTemplatesPage from '../pages/templates'
import AllSectionPage from '../pages/translation/section/';
import AllLanguage from '../pages/translation/language'
import TranslationPage from '../pages/translation'
import AllTranslationsPage from '../pages/translation/all-translations'
import ConfigurationsPage from '../pages/configurations'
import ConfigurationsAllPages from '../pages/configurations/pages'
import ConfigurationsShiftKeys from '../pages/configurations/shift-keys';
import ConfigurationsTicketKeys from '../pages/configurations/ticket-keys';
import ConfigurationsHQSettings from '../pages/configurations/hq-settings';
import AppMangerContainerComponent from "../pages/app-manager/"
import ManageVersionsContainerComponent from "../pages/manage-versions/"
import offlinePage from "../pages/off-line"
import onlinePage from "../pages/on-line"
import AllOnlineLanguage from '../pages/on-line/translation/language'
import AllOnlineSectionPage from '../pages/on-line/translation/section';
import AllOnlineTranslationsPage from '../pages/on-line/translation/all-translations';
import WebsitesPage from '../pages/on-line/websites';
import EditWebsite from '../pages/on-line/websites/single-website';
import OnlineConfigurationsPage from '../pages/on-line/configurations';
import ManageBetshopVersionsContainerComponent from '../pages/betshop-versions/'
import OnlineConfigurationSectionsPage from '../pages/on-line/configuration-settings/components/sections'
import OnlineConfigurationTypesPage from '../pages/on-line/configuration-settings/components/types'
import OnlineConfigurationSettingsPage from '../pages/on-line/configuration-settings/components/settings'
import ConfigurationSettings from '../pages/on-line/configuration-settings'
import {isDeveloper} from "@/guards/permissionGuard";

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', redirect: '/home' },
    {
      path: '/home', name: 'home', component: HomePage, meta: { authRequired: true },
      children: [
        {
          name: 'offline', path: 'off-line', component: offlinePage, children: [
            { name: 'users', path: 'all-users', component: AllUsers },
            { name: 'users-edit', path: 'all-users/:id', component: EditUSer },
            { name: 'templates', path: 'all-templates', component: AllTemplatesPage },
            { name: 'companies', path: 'companies', component: Companies },
            { name: 'markets', path: 'company/:companyId/markets', component: Markets },
            { name: 'betshops', path: 'company/:companyId/markets/:marketId/betshops', component: Betshops },
            { name: 'betshop', path: 'company/:companyId/markets/:marketId/betshops/:betshopId', component: SingleBetshop },
            {
              name: 'translation', path: 'translation', component: TranslationPage,
              children: [
                {
                  name: 'sections', path: 'section', component: AllSectionPage,
                },
                {
                  name: 'all-translations', path: 'all-translations', component: AllTranslationsPage,
                },
                {
                  name: 'languages', path: 'language', component: AllLanguage,
                },
              ]
            },
            {
              name: 'configurations', path: 'configurations', component: ConfigurationsPage,
              children: [
                {
                  name: 'pages', path: 'pages', component: ConfigurationsAllPages,
                },
                {
                  name: 'shift-keys', path: 'shift-keys', component: ConfigurationsShiftKeys,
                },
                {
                  name: 'ticket-keys', path: 'ticket-keys', component: ConfigurationsTicketKeys,
                },
                {
                  name: 'hq-settings', path: 'hq-settings', component: ConfigurationsHQSettings,
                },
              ]
            },
            { name: 'manage-drivers', path: 'manage-drivers', component: AppMangerContainerComponent },
            { name: 'manage-versions', path: 'manage-versions', component: ManageVersionsContainerComponent },
            { name: 'betshop-versions', path: 'betshop-versions', component: ManageBetshopVersionsContainerComponent },
          ]
        },
        {
          name: 'online', path: 'on-line', component: onlinePage, children: [
            { name: 'websites', path: 'websites', component: WebsitesPage },
            { name: 'websites-edit', path: 'websites/:id', component: EditWebsite },
            { name: 'on-line-users', path: 'all-users', component: AllUsers },
            { name: 'on-line-users-edit', path: 'all-users/:id', component: EditUSer },
            {
              name: 'on-line-translation', path: 'translation', component: TranslationPage,
              children: [
                {
                  name: 'on-line-all-translations',
                  path: 'all-translations',
                  component: AllOnlineTranslationsPage,
                },
                {
                  name: 'on-line-languages', path: 'language', component: AllOnlineLanguage,
                },
                {
                  name: 'on-line-sections', path: 'section', component: AllOnlineSectionPage,
                },
              ],
            },
            {
              name: 'on-line-configurations',
              path: 'configurations',
              component: OnlineConfigurationsPage,
            },
            {
              name: 'on-line-configurations-settings',
              path: 'on-line-configurations-settings',
              component: ConfigurationSettings,
              beforeEnter: isDeveloper,
              children: [
                {
                  name: 'online-configuration-sections',
                  path: 'sections',
                  component: OnlineConfigurationSectionsPage,
                  beforeEnter: isDeveloper,
                },
                {
                  name: 'online-configuration-types',
                  path: 'types',
                  component: OnlineConfigurationTypesPage,
                  beforeEnter: isDeveloper,
                },
                {
                  name: 'online-configuration-settings',
                  path: 'settings',
                  component: OnlineConfigurationSettingsPage,
                  beforeEnter: isDeveloper,
                },
              ],
            },
          ],
        },
      ]
    },
    { path: '/login', name: 'login', component: LoginPage, meta: { unauthenticated: true } },
    { path: '/activate', name: 'activate', component: ActivatePage, meta: { unauthenticated: true }}
  ]
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = authService.isLoggedIn();

  if (to.name === from.name) {
    return;
  }

  if (to.meta.authRequired && !isLoggedIn) {
    return next({ name: 'login' })
  }

  if (isLoggedIn && from.name !== 'login' && to.name !== 'login') {
    return authService.hasPermission(to, next);
  }

  if (isLoggedIn && to.name === 'login') {
    return next({name: 'companies'})
  }

  next();
})

export default router
