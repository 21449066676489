export const wsboPermission = {
  "companies": {
    permissions: [
      {
        key: 'companies',
        page: 'companies',
        pageTab: null,
        read: false,
        write: false,
      },
    ],
  },
  "templates": {
    permissions: [
      {
        key: 'ticket-template',
        page: null,
        pageTab: 'ticket-template',
        read: false,
        write: false,
      },
      {
        key: 'open-shift-template',
        page: null,
        pageTab: 'open-shift-template',
        read: false,
        write: false,
      },
      {
        key: 'close-shift-template',
        page: null,
        pageTab: 'close-shift-template',
        read: false,
        write: false,
      },
    ],
  },
  "translations": {
    permissions: [
      {
        key: 'all-translations',
        page: 'all-translations',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'sections',
        page: 'sections',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'languages',
        page: 'languages',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
    ],
  },

  "websites": {
    permissions: [
      {
        key: 'websites',
        page: 'websites',
        pageTab: null,
        read: false,
        write: false,
      },
      {
        key: 'websites-edit',
        page: 'websites',
        pageTab: 'websites-edit',
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'seo-rules',
        page: null,
        pageTab: 'seo-rules',
        read: false,
        write: false,
      },
      {
        key: 'tooltips',
        page: null,
        pageTab: 'tooltips',
        read: false,
        write: false,
      },
    ],
  },

  "configurations": {
    permissions: [
      {
        key: 'pages',
        page: 'pages',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'open-shift-keys',
        page: 'shift-keys',
        pageTab: 'open-shift-keys',
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'close-shift-keys',
        page: 'shift-keys',
        pageTab: 'close-shift-keys',
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'ticket-keys',
        page: 'ticket-keys',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
      {
        key: 'hq-settings',
        page: 'hq-settings',
        pageTab: null,
        read: false,
        write: false,
        subItem: true,
      },
    ],
  },
  "manage-drivers": {
    permissions: [
      {
        key: 'drivers',
        page: null,
        pageTab: 'drivers',
        read: false,
        write: false,
      },
      {
        key: 'versions',
        page: null,
        pageTab: 'versions',
        read: false,
        write: false,
      },
      {
        key: 'betshop-versions',
        page: null,
        pageTab: 'betshop-versions',
        read: false,
        write: false,
      }
    ],
  },
};

export const hierarchyPermission = {
  "companyIds": [],
  "marketIds": [],
  "betshopIds": [],
  "hierarchy": {
    "betShops": {
      isVisible: false,
      market: true,
      betshop: false,
      permissions: [
        {
          page: 'betShops',
          pageTab: null,
          marketRead: false,
          marketWrite: false,
        },
      ],
    },
    "settings": {
      isVisible: false,
      market: true,
      betshop: true,
      permissions: [
        {
          page: 'settings',
          pageTab: null,
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        },
      ],
    },
    "ticketSettings": {
      isVisible: false,
      market: true,
      betshop: true,
      permissions: [
        {
          page: 'ticketSettings',
          pageTab: 'print-settings',
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        },
        {
          page: 'ticketSettings',
          pageTab: 'ticket-descriptions',
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        },
        {
          page: 'ticketSettings',
          pageTab: 'ticket-top-section',
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        },
      ],
    },
    "shiftSettings": {
      isVisible: false,
      market: true,
      betshop: true,
      permissions: [
        {
          page: 'shiftSettings',
          pageTab: null,
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        }
      ],
    },
    "disabledPages": {
      isVisible: false,
      market: true,
      betshop: true,
      permissions: [
        {
          page: 'disabledPages',
          pageTab: null,
          betshopRead: false,
          betshopWrite: false,
          marketRead: false,
          marketWrite: false,
        },
      ],
    },
    "licenceKeys": {
      isVisible: false,
      market: false,
      betshop: true,
      permissions: [
        {
          page: 'licenceKeys',
          pageTab: null,
          betshopRead: false,
          betshopWrite: false
        },
      ],
    },
  }
};