<template>
  <div>
    <p>{{ input.label }}</p>
    <p>{{ input.description }}</p>

    <md-tabs
      class="c-tabs c-tabs--small"
      :md-active-tab="selectedLanguage"
    >
      <md-tab
        v-for="language in languages"
        :id="language"
        :key="`${input.id}-${language}`"
        :md-label="language"
        @click="changeLanguage(language)"
      />
    </md-tabs>

    <md-field
      v-for="field in fields"
      :key="field.name"
    >
      <label>{{ field.label }}</label>
      <md-input
        :value="getValue(selectedLanguage, field.key)"
        :name="field.key"
        type="text"
        :disabled="disabled"
        @input="setValue(selectedLanguage, field.key, $event)"
      />
    </md-field>
  </div>
</template>

<script>
import {get, setWith} from 'lodash';

export default {
  name: 'SocialShareMetaTagsFormField',

  props: {
    input: {
      type: Object,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
  },

  data() {
    return {
      inputValue: {},
      fields: [
        {key: 'ogTitle', label: 'Og Title'},
        {key: 'ogDescription', label: 'Og Description'},
        {key: 'ogType', label: 'Og Type'},
        {key: 'ogSiteName', label: 'Og Site Name'},
        {key: 'ogImage', label: 'Og Image (Link to image)'},
        {key: 'ogTwitterCard', label: 'Og Twitter Card'},
      ],
      selectedLanguage: this.languages[0],
    }
  },

  computed: {
    defaultInputValue() {
      try {
        return JSON.parse(get(this.input, 'defaultValue', {}))
      } catch (e) {
        return {}
      }
    },
  },

  created() {
    const inputValues = get(this.input, 'value', this.defaultInputValue)
    this.inputValue = inputValues || {}
  },

  methods: {
    changeLanguage(language) {
      this.selectedLanguage = language
    },

    getValue(language, input) {
      return get(
          this.inputValue,
          `${ language }.${ input }`,
          get(
              this.defaultInputValue,
              `${ language }.${ input }`,
              '',
          ),
      );
    },

    async setValue(language, input, value) {
      this.inputValue = {...this.inputValue};
      setWith(this.inputValue, `${ language }.${ input }`, value, Object)

      this.$emit('update', {
        value: JSON.stringify(this.inputValue),
      })
    },
  },
}

</script>
<style lang="scss" scoped>
.inputs-wrapper {
  display: flex;
  padding: 10px;

  div {
    width: 100%;
    margin: 10px;
  }

  textarea {
    width: 100%;
  }
}

label {
  display: block;
}
</style>
